import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatPaginatorIntl, MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import exportFromJSON from 'export-from-json';
import { jsPDF } from 'jspdf';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { TestService } from '../../../services/test.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import 'jspdf-autotable';

const commaSepEmail = (
  control: AbstractControl
): { [key: string]: any } | null => {
  if (control.value) {
    const emails = control.value.split(',').map((e) => e.trim());
    const forbidden = emails.some((email) =>
      Validators.email(new FormControl(email))
    );
    return forbidden ? { toAddress: { value: control.value } } : null;
  }
};

@Component({
  selector: 'app-tabular',
  templateUrl: './tabular.component.html',
  styleUrls: ['./tabular.component.css'],
  animations: [
    trigger('detailExpand', [
      state(
        'collapsed, void',
        style({ height: '0px', minHeight: '0', visibility: 'hidden' })
      ),
      state('expanded', style({ height: '*', visibility: 'visible' })),
      transition(
        'expanded <=> collapsed, void <=> *',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class TabularComponent implements OnInit, OnChanges {
  @Output() openSingleTestModal: EventEmitter<any> = new EventEmitter();
  @Input() data: any;
  @Input() originalRes: any;
  displayedColumns: string[] = [];
  secondDisplayedColumns: string[] = [];
  dataSource: MatTableDataSource<any>;
  dataSourceNull: MatTableDataSource<any> = new MatTableDataSource(null);
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  analytes: any;
  tableData: any;
  rangeData: any;
  currentDate: Date;
  fileName: string;
  currentPatient: any = JSON.parse(localStorage.getItem('patient')) || {};
  formatForEmail: FormControl = new FormControl('', [Validators.required]);
  toEmails: FormControl = new FormControl('', [
    Validators.required,
    commaSepEmail,
  ]);
  ccEmails: FormControl = new FormControl('', [commaSepEmail]);
  sendEmailForm: FormGroup = new FormGroup({});
  downloadButtons = [
    {
      text: 'reports.csv',
      type: 'csv',
    },
    {
      text: 'reports.json',
      type: 'json',
    },
    {
      text: 'reports.excel',
      type: 'xls',
    },
    {
      text: 'reports.pdf',
      type: 'pdf',
    },
  ];
  constructor(
    private translate: TranslateService,
    public customPaginatorIntl: MatPaginatorIntl,
    private dateFilter: DatePipe,
    private changeDetectorRef: ChangeDetectorRef,
    private _testService: TestService,
    private _ngxService: NgxUiLoaderService
  ) {
    this.patientSubscription();
  }

  ngOnInit() { }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  ngOnChanges() {
    this.customPaginatorIntl.itemsPerPageLabel =
      this.translate.instant('common.itemPerPage');
    this.analytes = this.data.analytes;
    this.displayedColumns = ['date', ...this.analytes, 'Supplementary'];
    this.secondDisplayedColumns = this.displayedColumns.map((i) => i + '-1');
    this.tableData = this.data.tableData;
    this.rangeData = this.data.rangeData;
    this.dataSource = new MatTableDataSource(this.data.tableData);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.changeDetectorRef.detectChanges();
  }

  downloadTestReportResults(type) {
    try {
      this.setFileName();
      if (type === 'csv' || type === 'xls' || type === 'json')
        this.downloadFile(this.dataSource.data, type);
      else this.convertPdfData(this.dataSource.data);
    } catch (error) { }
  }

  downloadFile(arrayOfJson, ext) {
    const data = JSON.parse(JSON.stringify(arrayOfJson));

    data.forEach((v) => {
      const testIndex = this.originalRes.findIndex((obj) => obj.id === v.id);
      if (testIndex === -1) return;
      delete v.rangeData;
      delete v.id;
      delete v.health_id;

      const rangeDataForTest = this.rangeData[testIndex];

      Object.keys(v).forEach((key) => {
        rangeDataForTest.forEach((element) => {
          if (key === element.analytes) {
            v[`${element.analytes} Range`] =
              `${element.min}-${element.max} ${element.unit}`;
          }
        });
      });
    });

    const sortedData = data.map(this.sortKeys);
    const firstRow = { ...sortedData[0] };
    delete firstRow.COVS;
    const newData = [...sortedData];
    exportFromJSON({
      data: newData,
      fileName: `${this.fileName}`,
      exportType: exportFromJSON.types[ext],
    });
  }

  setFileName() {
    this.currentDate = new Date();
    this.fileName = `${this.currentPatient.forename}_${this.currentPatient.surname
      }_${this.dateFilter.transform(
        this.currentDate,
        'yyyyMMdd-HHmmss'
      )}_blood_results`;
  }

  displayRow(element) {
    this.openSingleTestModal.next(element);
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  getDatesForAllBrowser(date) {
    return this.dateFilter.transform(date.replace(' ', 'T'), 'dd-MMM-yyyy');
  }

  formatCell(cell) {
    switch (typeof cell) {
      case 'number':
        return +cell.toFixed(3);
      default:
        return '--';
    }
  }

  getRangeValues(label, i) {
    const matchingElement = this.rangeData[0].find(
      (element) => element.analytes === label.split('-')[0]
    );
    return matchingElement ? matchingElement.unit : '';
  }

  getCellBgColor(element, column) {
    const testIndex = this.originalRes.findIndex((obj) => obj.id === element.id);
    if (testIndex === -1) return false;

    const rangeItem = this.rangeData[testIndex].find((item) => item.analytes === column);
    if (!rangeItem) return false;

    const value = Number(element[column]);
    if (value === -1.0 || value === -1 || Number.isNaN(value)) return false;

    return value > rangeItem.max || value < rangeItem.min;
  }

  convertPdfData(arrayOfJson) {
    try {
      const data = JSON.parse(JSON.stringify(arrayOfJson));
      const headers = [];
      const rangeDataRow = {};

      const updatedData = this.processData(data);
      const sortedData = updatedData.map(this.sortKeys);
      const firstRow = { ...sortedData[0] };
      delete firstRow.COVS;

      this.buildHeadersAndRangeDataRow(headers, firstRow, rangeDataRow);

      const newData = this.buildNewData(headers, sortedData);
      const excelData = this.prepareExcelData(newData);

      this.generatePdf(headers, excelData);
    } catch (error) { }
  }

  processData(data) {
    return data.map((v) => {
      const testIndex = this.originalRes.findIndex((obj) => obj.id === v.id);
      if (testIndex === -1) return v;

      delete v.id;
      delete v.rangeData;
      delete v.health_id;

      v.date = this.getDatesForAllBrowser(v.date);

      const rangeDataForTest = this.rangeData[testIndex];

      Object.keys(v).forEach((key) => {
        rangeDataForTest.forEach((element) => {
          if (key === element.analytes) {
            v[`${element.analytes} Range`] =
              `${element.min}-${element.max} ${element.unit}`;
          }
        });
      });

      return v;
    });
  }

  sortKeys(obj) {
    const ordered = {};
    const keys = Object.keys(obj);

    const keyIndexMap = {};
    keys.forEach((key, index) => {
      keyIndexMap[key.split(' ')[0]] = index;
    });

    keys.sort((a, b) => {
      const aBase = a.split(' ')[0];
      const bBase = b.split(' ')[0];
      if (aBase === bBase) {
        return a.includes('Range') ? 1 : -1;
      }
      return keyIndexMap[aBase] - keyIndexMap[bBase];
    });

    keys.forEach((key) => {
      ordered[key] = obj[key];
    });

    return ordered;
  }

  buildHeadersAndRangeDataRow(headers, firstRow, rangeDataRow) {
    Object.keys(firstRow).forEach((header) => {
      headers.push(header);
      rangeDataRow[header] = '';
    });
  }

  buildNewData(headers, sortedData) {
    return sortedData.map((element) => {
      return headers.reduce((acc, header) => {
        acc[header] = element.hasOwnProperty(header) ? element[header] : '-1';
        return acc;
      }, {});
    });
  }

  prepareExcelData(newData) {
    return newData.map((v) =>
      Object.values(v).map((u) => (u === '-1' || u === '-1.0' ? '--' : u))
    );
  }

  generatePdf(headers, excelData) {
    const margin = 40;
    const doc = new jsPDF('l', 'px', 'a2', true);
    doc.text('Patient Data', margin, margin);
    doc.setTextColor(100);

    (doc as any).autoTable({
      head: [headers],
      body: excelData,
      startY: margin + 20,
      margin: { left: margin, right: margin },
    });

    doc.save(this.fileName);
  }

  patientSubscription() {
    this._testService.patient.subscribe((res: boolean) => {
      if (res) {
        this.currentPatient = JSON.parse(localStorage.getItem('patient'));
      }
    });
  }

  whenNoTags(element, column) {
    return (
      element[column] !== '-1.0' &&
      element[column] !== '-1' &&
      element[column] !== 'null' &&
      element[column] !== undefined
    );
  }
}
