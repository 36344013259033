<ngx-ui-loader></ngx-ui-loader>

<div class="container pt-3 pb-5" style="position: relative">

  <app-scores-overview *ngIf="isInitialised && resultSets.length" [resultSets]="resultSets"
    [isLoading]="isLoading"></app-scores-overview>

  <div class="mt-3">
    <app-score-over-time *ngIf="isInitialised && resultSets.length" [resultSets]="resultSets" [events]="events"
      [isLoading]="isLoading"></app-score-over-time>
  </div>

  <app-score-trends *ngIf="isInitialised && resultSets.length" [resultSets]="resultSets" [scores]="scores"
    [isLoading]="isLoading"></app-score-trends>

  <div *ngIf="error" class="mt-3 text-danger">
    {{ error }}
  </div>

  <div *ngIf="isInitialised && !resultSets.length && !error"
    class="section-border-background d-flex justify-content-center text-center w-100 px-3" style="min-height: 500px;">
    <h3 class="dashboardTitle"
      style="text-align: center !important;vertical-align: middle !important;line-height: 250px !important;">No Health
      Data Found</h3>
  </div>
</div>
