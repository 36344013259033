<ngx-ui-loader></ngx-ui-loader>
<div class="container">
  <section>
    <h5 id="example-radio-group-label">
      {{ 'foldingPlot.selectTriggerType' | translate }}
    </h5>
    <mat-radio-group aria-label="Select trigger type" [(ngModel)]="selectedPlot" (change)="selectedTriggerChanged()">
      <mat-radio-button class="mr-3" *ngFor="let triggerOption of triggerOptions" value="{{ triggerOption.value }}"
        [hidden]="!triggerOption.canView">{{ triggerOption.text | translate }}</mat-radio-button>
    </mat-radio-group>
  </section>

  <section>
    <div class="row">
      <div class="col-md-4 align-self-center" *ngIf="selectedPlot === 'date'">
        <mat-form-field class="w-100 mt-2">
          <input matInput [(ngModel)]="selectedPlotDate" placeholder="{{ 'common.selectStartDate' | translate }}"
            [matDatepicker]="myDatepicker" autocomplete="off" [min]="minDate" [max]="maxDate"
            (dateChange)="onChangeStartDate($event)" />
          <mat-datepicker-toggle matSuffix [for]="myDatepicker"></mat-datepicker-toggle>
          <mat-datepicker #myDatepicker></mat-datepicker>
        </mat-form-field>
      </div>

      <div class="col-md-4 align-self-center" *ngIf="selectedPlot === 'date' && selectedPlotDate">
        <mat-form-field class="w-100 mt-2">
          <input matInput [(ngModel)]="selectedPlotEndDate" placeholder="{{ 'common.selectEndDate' | translate }}"
            [matDatepicker]="myDatepickerEnd" autocomplete="off" [min]="minDateEnd" [max]="maxDate" />
          <mat-datepicker-toggle matSuffix [for]="myDatepickerEnd"></mat-datepicker-toggle>
          <mat-datepicker #myDatepickerEnd></mat-datepicker>
        </mat-form-field>
      </div>

      <div class="col-md-4 align-self-center" *ngIf="selectedPlot == 'dateSeries'">
        <mat-form-field class="w-100 mt-2">
          <input matInput placeholder="{{ 'foldingPlot.selectSeriesOfDates' | translate }}"
            [matDatepicker]="myDateSeriesPicker" autocomplete="off" readonly />
          <mat-datepicker-toggle matSuffix [matMenuTriggerFor]="appMenu"></mat-datepicker-toggle>
          <mat-menu #appMenu="matMenu">
            <div (click)="$event.stopPropagation()">
              <mat-calendar [minDate]="minDate" [maxDate]="maxDate" #calendar
                (selectedChange)="select($event, calendar)" [dateClass]="isSelected">
              </mat-calendar>
            </div>
          </mat-menu>
        </mat-form-field>
      </div>

      <div class="col-md-4 align-self-center" *ngIf="selectedPlot == 'event'">
        <mat-form-field class="mt-1 w-100">
          <mat-label>{{ 'foldingPlot.selectEvent' | translate }}</mat-label>
          <mat-select [value]="selectedEvent" (selectionChange)="eventChange($event)" multiple>
            <mat-option *ngFor="let event of events" [value]="event">
              <span class="ng-value-icon left">
                <img width="20" height="20" class="img-circle mr-1" src="assets/health-event/{{ event.id }}.svg" />
              </span>
              <span class="ng-value-label">{{ event.name }}</span>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="col-md-3 align-self-center" *ngIf="selectedPlot === 'analyte'">
        <mat-form-field class="mt-1 w-100">
          <mat-label>{{ 'foldingPlot.selectAnalyte' | translate }}</mat-label>
          <mat-select [value]="selectedAnalyteForCompare" (selectionChange)="analyteChange($event)">
            <mat-option *ngFor="let item of rangeData" [value]="item">
              {{ item.label }} {{item.unit}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="col-md-3 align-self-center" *ngIf="selectedPlot === 'analyte' && selectedAnalyteForCompare">
        <mat-form-field class="w-100">
          <mat-label>{{ 'foldingPlot.enterValue' | translate }}</mat-label>
          <input matInput type="number" [(ngModel)]="analyteValueToCompare"
            placeholder="{{ 'foldingPlot.enterValue' | translate }}" autocomplete="off" />
          <span matSuffix>{{ selectedAnalyteForCompare.unit }}</span>
        </mat-form-field>
      </div>

      <div class="col-md-2 align-self-center" *ngIf="selectedPlot === 'analyte'">
        <mat-radio-group aria-label="Select trigger type" [(ngModel)]="comparator">
          <mat-radio-button class="mr-3" value="lthen">
            {{ 'foldingPlot.lower' | translate }}
          </mat-radio-button>
          <mat-radio-button class="mr-3" value="gthen">
            {{ 'foldingPlot.higher' | translate }}
          </mat-radio-button>
        </mat-radio-group>
      </div>

      <div class="col-md-2 align-self-center" *ngIf="resultDates && resultDates.length">
        <h6 class="mb-3">{{ 'foldingPlot.plotLength' | translate }}</h6>
        <mat-slider class="w-100" thumbLabel [displayWith]="formatLabel" tickInterval="7" min="0" max="28"
          [(ngModel)]="plotLength">
        </mat-slider>
      </div>

      <div class="col-md-2 align-self-center">
        <button class="btn btn-success" [disabled]="isShowFoldingPlot()" (click)="
            selectedPlot === 'date'
              ? generateDatePlotGraph()
              : selectedPlot === 'dateSeries'
                ? generateDateSeriesPlotGraph()
                : selectedPlot === 'analyte'
                  ? generateAnalytePlotGraph()
                  : selectedPlot == 'event'
                    ? generateEventTriggerPlot()
                    : null
          ">
          {{ 'foldingPlot.showFoldingPlot' | translate }}
        </button>
      </div>
    </div>

    <div class="row" *ngIf="
        selectedPlot == 'dateSeries' && daysSelected && daysSelected.length
      ">
      <p>
        {{ 'foldingPlot.selectedDays' | translate }} :
        <span *ngFor="let date of daysSelected">{{ date | date: config.regionConfig.dateFormatMid }},
        </span>
      </p>
    </div>

    <div class="mt-5" *ngIf="isError">
      <h2 class="text-center">{{ errMsg }}</h2>
    </div>

    <div class="mt-5" *ngIf="allAnalytes && allAnalytes.length <= 0">
      <h2 class="text-center">
        {{ 'foldingPlot.noHealthDataPresent' | translate }}
      </h2>
    </div>
  </section>

  <section class="mt-3">
    <div class="row text-left" *ngIf="allAnalytes.length">
      <mat-selection-list [(ngModel)]="selectedAnalyte" style="display: flex; flex-wrap: wrap; width: 100%">
        <mat-list-option class="w-auto" [checkboxPosition]="before" [value]="analyte"
          *ngFor="let analyte of allAnalytes">
          {{ analyte }}
        </mat-list-option>
      </mat-selection-list>
    </div>
  </section>

  <section *ngIf="displayGraph">
    <div class="row">
      <div class="col-1 p-0">
        <div class="example-section" style="margin: 150% auto; text-align: center">
          {{ 'foldingPlot.linear' | translate }}
          <mat-slide-toggle class="example-margin" color="accent" (change)="scaleChange($event)" style="
              transform: rotate(270deg);
              writing-mode: vertical-lr;
              margin: 10px auto;
              display: flex;
              justify-content: center;
            ">
          </mat-slide-toggle>
          {{ 'foldingPlot.logarithmic' | translate }}
        </div>
      </div>
      <div class="col-11">
        <div id="foldPlot"></div>
      </div>
    </div>
  </section>
</div>