<ngx-ui-loader></ngx-ui-loader>

<!-- Event Page START -->
<div class="container">
  <div class="row">
    <div class="col-md-10 col-lg-10 col-12" style="text-align: left">
      <h2 class="title-text">Events</h2>
      <p class="subTitle-text">
        Contact your healthcare professional for any emergency or concerns about
        your health
      </p>
    </div>
    <div class="col-md-2 col-lg-2 col-12">
      <button mat-raised-button class="add-event" href="javascript:void(0)" id="open-event-modal-button"
        (click)="openModal('create'); eventForm.reset()" data-backdrop="static" data-keyboard="false"
        [disabled]="!userData.isProfileComplete">
        <mat-icon aria-hidden="false" aria-label="Example home icon" fontIcon="add"></mat-icon> New Event
      </button>
    </div>
  </div>
  <div class="row">
    <div class="col-12 col-md-6">
      <mat-form-field appearance="outline">
        <mat-icon matPrefix>search</mat-icon>
        <input matInput placeholder="Search by Name" [(ngModel)]="searchText" #search />
      </mat-form-field>
    </div>
    <div class="col-12 col-md-3">
      <mat-form-field appearance="outline">
        <mat-date-range-input [formGroup]="rangeFormGroup" [rangePicker]="picker" [min]="minEventDate" [max]="today">
          <input matStartDate placeholder="Start date" formControlName="start" />
          <input matEndDate placeholder="End date" formControlName="end" />
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker">
          <mat-icon matDatepickerToggleIcon>date_range</mat-icon>
        </mat-datepicker-toggle>
        <mat-date-range-picker #picker>
          <mat-date-range-picker-actions>
            <button mat-button matDateRangePickerCancel>Cancel</button>
            <button mat-button matDateRangePickerCancel (click)="applyDate(false)"
              style="background-color: #e35f6c; color: #ffffff">
              Clear
            </button>
            <button mat-raised-button style="background-color: #00978d; color: #ffffff" matDateRangePickerApply
              (click)="applyDate(true)">
              Apply
            </button>
          </mat-date-range-picker-actions>
        </mat-date-range-picker>
      </mat-form-field>
    </div>
    <div class="col-12 col-md-3">
      <mat-form-field appearance="outline">
        <mat-select #select [(ngModel)]="this.selectedEvents" placeholder="All Events" multiple>
          <div class="select-all">
            <mat-checkbox [(ngModel)]="allSelected" [ngModelOptions]="{ standalone: true }"
              (change)="toggleAllSelection()">Select All</mat-checkbox>
          </div>
          <mat-option (click)="optionClick()" *ngFor="let event of events" [value]="event.id" selected>
            <span class="ng-value-icon left">
              <img width="20" height="20" class="img-circle mr-1" src="assets/health-event/{{ event.id }}.svg" />
            </span>
            <span class="ng-value-label">{{ event.name }}</span>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div class="row" *ngIf="events && events.length">
    <!-- Desktop Design Table -->
    <div class="col-12 mb-4 d-none d-md-block d-lg-block">
      <div class="mat-elevation-z8">
        <table mat-table [dataSource]="dataSource" matSort (matSortChange)="sortData($event)">
          <ng-container matColumnDef="eventTypes">
            <th mat-header-cell *matHeaderCellDef>Event Name</th>
            <td mat-cell *matCellDef="let element" class="ellipsis">
              <p style="padding-right: 15px">
                <span *ngFor="let event of element.eventTypes">
                  <img src="assets/health-event/{{ event.id }}.svg" width="20px" height="20px"
                    style="margin-right: 5px" />
                  <span style="margin-right: 5px">
                    {{ event.name }}
                  </span>
                </span>
              </p>
            </td>
          </ng-container>

          <ng-container matColumnDef="eventDate" style="margin-left: 10px; margin-right: 10px">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Event Date">
              <span> </span> Event Date
            </th>
            <td mat-cell *matCellDef="let element">
              {{
              element.eventDate | date: config.regionConfig.dateFormateShortV2
              }}
            </td>
          </ng-container>

          <ng-container matColumnDef="wellnessRating" style="margin-left: 10px; margin-right: 10px">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Feeling">
              Feeling
            </th>
            <td mat-cell *matCellDef="let element">
              <span *ngIf="element.wellnessRating">
                {{ element.wellnessRating }}/10</span>
              <span *ngIf="!element.wellnessRating"> -- </span>
            </td>
          </ng-container>

          <ng-container matColumnDef="painRating" style="margin-left: 10px; margin-right: 10px; min-width: 10%">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Pain">
              Pain
            </th>
            <td mat-cell *matCellDef="let element" style="text-align: center !important">
              <span *ngIf="element.painRating">
                {{ element.painRating }}/10
              </span>
              <span *ngIf="!element.painRating"> -- </span>
            </td>
          </ng-container>

          <ng-container matColumnDef="energyRating" style="margin-left: 10px; margin-right: 10px; min-width: 10%">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Energy">
              Energy
            </th>
            <td mat-cell *matCellDef="let element">
              <span *ngIf="element.energyRating">{{ element.energyRating }}/10</span>
              <span *ngIf="!element.energyRating"> -- </span>
            </td>
          </ng-container>

          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element; let i = index" style="text-align: right">
              <span class="view-details" (click)="viewEvent(element.id, i)">
                View Details
              </span>
              <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu" xPosition="after">
                <button mat-menu-item (click)="editEvent(element.id, i)">
                  <mat-icon>edit_outlined</mat-icon>
                  <span>Edit</span>
                </button>
                <button mat-menu-item (click)="deleteEvent(element.id, i)">
                  <mat-icon class="material-symbols-outlined"
                    style="color: #e35f6c">do_not_disturb_on_outlined</mat-icon>
                  <span style="color: #e35f6c">Remove</span>
                </button>
              </mat-menu>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>

        <div class="mat-paginator-container">
          <div class="mat-paginator-range-label">
            {{ totalRecords }} Results Found
          </div>
          <div class="mat-paginator-navigation">
            <button mat-button class="custom-page" [disabled]="pageNo === 0 || totalRecords <= 7"
              (click)="goToPreviousPage()">
              <mat-icon>arrow_left</mat-icon>
              Prev
            </button>
            <button mat-button class="custom-page" [visible]="isPageShow(pageNumber)"
              *ngFor="let pageNumber of getPageNumbers()" [disabled]="pageNumber === pageNo"
              (click)="goToPage(pageNumber)">
              {{ pageNumber + 1 }}
            </button>
            <button mat-button class="custom-page" [disabled]="(pageNo === getTotalPages() - 1) || totalRecords <= 7"
              (click)="goToNextPage()">
              Next <mat-icon>arrow_right</mat-icon>
            </button>
          </div>
        </div>
        <mat-paginator style="visibility: hidden; display: none" [pageSize]="limit" [pageIndex]="pageNo"
          [showTotalPages]="3" [length]="totalRecords" (page)="pageEvents($event)">
        </mat-paginator>
      </div>
    </div>

    <!-- Mobile Design Table -->
    <div class="col-6 mat-paginator-container d-block d-md-none d-lg-none">
      <p style="margin-top: 12px; margin-left: 7px; font-weight: 700">
        {{ totalRecords }} Results Found
      </p>
    </div>
    <div class="col-6 mat-paginator-container d-block d-md-none d-lg-none">
      <mat-form-field appearance="outline">
        <mat-label style="font-weight: 700">Current Page No.</mat-label>
        <mat-select #select2 [(ngModel)]="pageNo" placeholder="Pages">
          <mat-option (click)="goToPage(pageNumber)" *ngFor="let pageNumber of getPageNumbers()" [value]="pageNumber">
            <span class="ng-value-label" [disabled]="pageNo === getTotalPages() - 1">{{ pageNumber + 1 }}</span>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="example-container mb-4 mat-elevation-z8 d-block d-md-none d-lg-none">
      <table mat-table [dataSource]="dataSource" matSort (matSortChange)="sortData($event)">
        <ng-container matColumnDef="eventTypes" sticky>
          <th mat-header-cell *matHeaderCellDef>Event Name</th>
          <td mat-cell *matCellDef="let element" class="ellipsis" matTooltip="Hello">
            <p style="padding-right: 15px">
              <span *ngFor="let event of element.eventTypes">
                <img src="assets/health-event/{{ event.id }}.svg" width="20px" height="20px"
                  style="margin-right: 5px" />
                <span style="margin-right: 5px">
                  {{ event.name }}
                </span>
              </span>
            </p>
          </td>
        </ng-container>

        <ng-container matColumnDef="eventDate" style="margin-left: 10px; margin-right: 10px">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Event Date">
            <span> </span>
            Event Date
          </th>
          <td mat-cell *matCellDef="let element">
            {{
            element.eventDate | date: config.regionConfig.dateFormateShortV2
            }}
          </td>
        </ng-container>

        <ng-container matColumnDef="wellnessRating" style="margin-left: 10px; margin-right: 10px">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Feeling">
            Feeling
          </th>
          <td mat-cell *matCellDef="let element">
            <span *ngIf="element.wellnessRating">
              {{ element.wellnessRating }}/10</span>
            <span *ngIf="!element.wellnessRating"> -- </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="painRating" style="margin-left: 10px; margin-right: 10px; min-width: 10%">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Pain">
            Pain
          </th>
          <td mat-cell *matCellDef="let element" style="text-align: center !important">
            <span *ngIf="element.painRating">
              {{ element.painRating }}/10
            </span>
            <span *ngIf="!element.painRating"> -- </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="energyRating" style="margin-left: 10px; margin-right: 10px; min-width: 10%">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Energy">
            Energy
          </th>
          <td mat-cell *matCellDef="let element">
            <span *ngIf="element.energyRating">{{ element.energyRating }}/10</span>
            <span *ngIf="!element.energyRating"> -- </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="action" stickyEnd>
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element; let i = index" style="text-align: right">
            <!-- <span class="view-details" (click)="viewEvent(element.id, i)">
              View Details
            </span> -->
            <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu" xPosition="after">
              <button mat-menu-item (click)="viewEvent(element.id, i)">
                <mat-icon style="color: #00978d">visibility</mat-icon>
                <span style="color: #00978d">View</span>
              </button>
              <button mat-menu-item (click)="editEvent(element.id, i)">
                <mat-icon>edit_outlined</mat-icon>
                <span>Edit</span>
              </button>
              <button mat-menu-item (click)="deleteEvent(element.id, i)">
                <mat-icon class="material-symbols-outlined" style="color: #e35f6c">do_not_disturb_on_outlined</mat-icon>
                <span style="color: #e35f6c">Remove</span>
              </button>
            </mat-menu>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>

    <mat-paginator style="visibility: hidden; display: none" [pageSize]="limit" [pageIndex]="pageNo"
      [showTotalPages]="3" [length]="totalRecords" (page)="pageEvents($event)">
    </mat-paginator>
  </div>
</div>

<!-- Event Page Ends -->

<!-- Event Page Modals Starts  -->

<!-- Create / Update Event Starts -->
<div class="modal fade p-0" id="open-event-modal" tabindex="-1" role="dialog"
  aria-labelledby="patientToPatientLinkTitle" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title text-center modal-title-text" id="patientToPatientLinkTitle">
          {{ eventDetails && eventDetails.id ? 'Edit' : 'New' }} Event
        </h5>
        <mat-icon class="material-symbols-outlined" style="cursor: pointer" (click)="closeModal('create')"
          aria-hidden="false" aria-label="Example home icon" fontIcon="close"></mat-icon>
      </div>
      <form [formGroup]="eventForm">
        <div class="modal-body">
          <div class="row">
            <div class="form-group col-12" *ngFor="let question of eventList">

              <mat-form-field class="example-full-width" appearance="outline" *ngIf="question.type === 'datetime'">
                <mat-label>{{ question.help_text }}</mat-label>
                <input matInput id="{{ question.formControlName }}" (click)="myDatepicker.open()"
                  (focus)="myDatepicker.open()" formControlName="{{ question.formControlName }}"
                  [(ngModel)]="question.givenAns[0]" [placeholder]="question.help_text"
                  [min]="selectedSubscriber.createdAt" [max]="today"
                  [matDatepicker]="myDatepicker" autocomplete="off" />
                <mat-datepicker-toggle matSuffix [for]="myDatepicker">
                  <mat-icon matDatepickerToggleIcon>calendar_today</mat-icon>
                </mat-datepicker-toggle>
                <mat-datepicker #myDatepicker></mat-datepicker>
              </mat-form-field>


              <div class="col-12 no-padding margin-top-10" *ngIf="question.type === 'choice'">

                <label>{{ question.help_text }}</label>
                <bar-rating id="{{ question.formControlName }}" formControlName="{{ question.formControlName }}"
                  [theme]="'square'" [max]="10" [(ngModel)]="question.givenAns" (rateChange)="
                    onRatingChange($event, question.formControlName)
                  " (mouseover)="onMouseOver($event, question.formControlName)"
                  (mouseout)="onMouseOut($event, question.formControlName)"></bar-rating>
                <!-- </div> -->
                <div class="row">
                  <div class="col-4 text-left bar-info" *ngIf="question.type === 'choice'">
                    Not Good
                  </div>
                  <div class="col-4 text-center bar-info" *ngIf="question.type === 'choice'">
                    Neutral
                  </div>
                  <div class="col-4 text-right bar-info" *ngIf="question.type === 'choice'">
                    Excellent
                  </div>
                </div>
              </div>

              <ng-select appearance="outline" [placeholder]="question.help_text" id="{{ question.formControlName }}"
                [items]="question.suggestedAns" [multiple]="true" bindLabel="label" bindValue="id"
                [closeOnSelect]="false" formControlName="{{ question.formControlName }}"
                *ngIf="question.type === 'field'" [(ngModel)]="question.givenAns">
                <ng-template ng-label-tmp let-item="item" let-clear="clear"
                  style="background-color: #e1f6f5 !important">
                  <span class="ng-value-icon d-inline-block left">
                    <img width="20" height="20" class="bg-light img-circle mr-2"
                      src="assets/health-event/{{ item.id }}.svg" />
                  </span>
                  <span class="ng-value-label">{{ item.name }}</span>
                  <span class="ng-value-icon right ml-1" (click)="clear(item)" aria-hidden="true"
                    style="color: #777777">×</span>
                </ng-template>
                <ng-template ng-option-tmp let-item="item" style="align-items: center">
                  <span class="ng-value-icon left">
                    <img width="20" height="20" class="img-circle mr-1" src="assets/health-event/{{ item.id }}.svg" />
                  </span>
                  <span class="ng-value-label">{{ item.name }}</span>
                  <mat-icon *ngIf="isSelected(item)" class="material-symbols-outlined" style="
                      color: #00978d;
                      float: right;
                      font-size: 17px;
                      margin-top: 12px;
                    " aria-hidden="false" aria-label="Example home icon" fontIcon="check_circle"></mat-icon>
                </ng-template>
              </ng-select>

              <mat-form-field class="example-full-width" appearance="outline" *ngIf="question.type === 'string'">
                <mat-label>{{ question.help_text }}</mat-label>
                <textarea rows="3" id="{{ question.formControlName }}" matInput [(ngModel)]="question.givenAns"
                  formControlName="{{ question.formControlName }}" placeholder="{{ question.help_text }}"></textarea>
              </mat-form-field>
            </div>

          </div>
        </div>
        <div class="modal-footer">
          <!-- Submit Button -->
          <button type="button" class="btn close-event" id="close-event-horizons" (click)="closeModal('create')">
            {{ 'common.closeBtnText' | translate }}
          </button>
          <button type="submit" class="btn save-event" id="create-event-horizons" (click)="
              eventDetails && eventDetails.id
                ? updateEventHorizons(eventList, eventDetails.id)
                : createEventHorizons(eventList)
            " [disabled]="isValidCreateEvent()">
            {{ 'common.saveBtnText' | translate }}
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
<!-- Create / Update Event Ends -->

<!-- Event Information Starts -->
<div class="modal fade p-0" id="open-view-event-modal" tabindex="-1" role="dialog"
  aria-labelledby="patientToPatientLinkTitle" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title text-center modal-title-text" id="patientToPatientLinkTitle">
          Event Details
        </h5>
        <mat-icon class="material-symbols-outlined" style="cursor: pointer" (click)="closeModal('view')"
          aria-hidden="false" aria-label="Example home icon" fontIcon="close"></mat-icon>
      </div>
      <div class="modal-body">
        <div class="row" *ngIf="eventDetails && eventDetails.id" style="padding: 15px">
          <div class="col-12 event-information">
            <span class="event-information-text"> Event Date</span>
            <span class="event-information-value">
              {{
              eventDetails.eventDate
              | date: config.regionConfig.dateFormateShortV2
              }}</span>
          </div>
          <div class="col-12 event-information">
            <span class="event-information-text"> How are you feeling?</span>
            <span class="event-information-value" *ngIf="eventDetails.wellnessRating">
              {{ eventDetails.wellnessRating }}/10</span>
            <span class="event-information-value" *ngIf="!eventDetails.wellnessRating">
              --
            </span>
          </div>
          <div class="col-12 event-information">
            <span class="event-information-text"> How is your pain?</span>
            <span class="event-information-value" *ngIf="eventDetails.painRating">
              {{ eventDetails.painRating }}/10</span>
            <span class="event-information-value" *ngIf="!eventDetails.painRating">
              --
            </span>
          </div>
          <div class="col-12 event-information">
            <span class="event-information-text"> Rate your energy level</span>
            <span class="event-information-value" *ngIf="eventDetails.energyRating">
              {{ eventDetails.energyRating }}/10</span>
            <span class="event-information-value" *ngIf="!eventDetails.energyRating">
              --
            </span>
          </div>
          <div class="col-12 event-information">
            <span class="event-information-text"> Relevant Event</span>
            <span class="event-information-value" *ngFor="let event of eventDetails.eventTypes">
              <img src="assets/health-event/{{ event.id }}.svg" width="20px" height="20px" style="margin-right: 10px" />
              <span class="event-information-value">
                {{ event.name}}
              </span>
            </span>
          </div>
        </div>
        <div class="row" *ngIf="eventDetails && eventDetails.id" style="padding: 5px">
          <div class="col-12">
            <h5 class="event-information-text-other">Other Information</h5>
            <p class="event-information-text" *ngIf="eventDetails.details">
              {{ eventDetails.details }}
            </p>
            <p class="event-information-text" *ngIf="!eventDetails.details">
              --
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Event Information Ends -->

<!-- Remove remove event confirmation Start -->
<div class="modal fade p-0" id="open-remove-confirmation-event-modal" tabindex="-1" role="dialog"
  aria-labelledby="patientToPatientLinkTitle" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title text-center modal-title-text" id="patientToPatientLinkTitle">
          Remove Event
        </h5>
        <mat-icon class="material-symbols-outlined" style="cursor: pointer" (click)="closeModal('remove')"
          aria-hidden="false" aria-label="Example home icon" fontIcon="close"></mat-icon>
      </div>
      <div class="modal-body">
        <div class="row justify-content-center">
          <div class="col-10">
            <div class="row justify-content-center">
              <div class="mt-2 mb-3 red-square">
                <mat-icon aria-hidden="false" class="red-square-icon" aria-label="Example home icon"
                  fontIcon="assignment_late" style="height: 42px; width: 42px; font-size: 42px"></mat-icon>
              </div>
              <div class="col-12 text-center">
                <h3 class="confirmation-title">Are you sure?</h3>
              </div>
              <div class="col-12 text-center">
                <p class="confirmation-info">
                  This action will remove the event and you will not able to
                  restore or withdraw
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="row justify-content-between">
          <div class="col-12 text-center">
            <button class="btn close-event" style="margin-right: 12px" (click)="closeModal('remove')">
              Close
            </button>
            <button class="btn save-event" id="remove-event-horizons" style="width: auto !important"
              (click)="removeEvent(eventDetails.id)">
              Yes, Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Remove remove event confirmation Ends -->
<!-- Event Page Modals Ends  -->
