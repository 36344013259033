export const environment = {
  production: true,
  client: 'algocyte-wellness',
  OcpApimSubscriptionKey: 'c404897dfbff47d99b74cd8c5c4d6a35',
  oia0TenantConfig: {
    baseUrl: 'https://oia0.b2clogin.com/',
    tenant: 'oia0.onmicrosoft.com',
    clientID: '416f63e7-d97b-40c9-b56b-44c7c3d5d842',
    signInPolicy: 'B2C_1_SISU',
    signUpPolicy: 'B2C_1_SISU',
    resetPAsswordPolicy: 'B2C_1_PRES',
    editPolicy: 'B2C_1_EP',
    b2cScopes: ['https://oia0.onmicrosoft.com/api/Hello.Read'],
  },
  oiaUatTenantConfig: {
    baseUrl: 'https://oiauat.b2clogin.com/',
    tenant: 'oiauat.onmicrosoft.com',
    clientID: '33e7cea7-de8f-4e4e-94e8-7e617099724a',
    signInPolicy: 'B2C_1_uatsigninsignup',
    signUpPolicy: 'B2C_1_uatsigninsignup',
    resetPAsswordPolicy: 'B2C_1_uatpassword',
    editPolicy: 'B2C_1_EP',
    b2cScopes: ['https://oiauat.onmicrosoft.com/api/demo.read'],
  },
  textMessage: 'For evaluation purposes only, Formative Usability Evaluation',
  demoMessage: 'Demo system - internal use only',
  feedbackMessage: 'Contact support at ',
  feedbackMail: 'support@algocyte.com',
  environment: 'prod',
  firebaseConfig: {
    apiKey: 'AIzaSyCAQuXruzxgVhc6JOuNNEdeCLAS_iMGFOk',
    authDomain: 'oia-715ac0b4c28a40e4-prod.firebaseapp.com',
    projectId: 'oia-715ac0b4c28a40e4-prod',
    storageBucket: 'oia-715ac0b4c28a40e4-prod.appspot.com',
    messagingSenderId: '824744171059',
    appId: '1:824744171059:web:1adace8de0d96ea272b546',
  },
  baseUrl: 'https://oia-715ac0b4c28a40e4-prod.nw.r.appspot.com',
  cookieYesAppId: 'd2d283c9d27772304c482ed9',
  enableTestHelper: false,
  maintenanceMode: false,
};
