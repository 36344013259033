import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  HostListener,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Router, NavigationExtras, NavigationEnd } from '@angular/router';
import { TestService } from './../../services/test.service';
import { config } from './../../config';
import { environment } from './../../../environments/environment';
import { Subject, BehaviorSubject, merge } from 'rxjs';
import { AuthInterceptor } from './../../services/interceptor';
import { User } from 'src/types';
import { AuthService } from 'src/app/services/auth/auth.service';
import { SelectedSubscriberService } from '../../services/selected-subscriber/selected-subscriber.service';
import { filter } from 'rxjs/operators';
import { UserService } from '../../services/user/user.service';
import { RoleService } from '../../services/user/role.service';
import { BaseComponent } from '../../modules/shared/base.component';
import { MatMenuTrigger } from '@angular/material/menu';
import { chdir } from 'process';
declare const $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css', '../../app.component.css'],
})
export class HeaderComponent
  extends BaseComponent
  implements OnInit, AfterViewInit {
  config = config;
  title = 'Algocyte';
  isLoggedin = false;
  isHidden = false;
  count = 0;
  isGuestUser = false;
  patientId = localStorage.getItem('patientId');
  selectedProfile: any = JSON.parse(localStorage.getItem('testProfile'))
    ? JSON.parse(localStorage.getItem('testProfile'))
    : { viewValue: 'Complete Test Profile', analytes: [], id: 'null' };
  profiles = [];
  currentPatient: any = {};
  feedbackMessage = environment.feedbackMessage
    ? environment.feedbackMessage
    : '';
  feedbackMail = environment.feedbackMail ? environment.feedbackMail : '';
  message: Subject<string>;
  childVisible = true;
  navOpen = false;
  userData: User;
  navItems: any[] = [];
  mobileNavItems: any[] = [];
  userRole: number;
  menuStates: boolean[] = [];
  currentUser: User;
  currentDonutView = 'Haematology';
  @ViewChild(MatMenuTrigger) menuTrigger: MatMenuTrigger;
  closeMenuTimer: NodeJS.Timeout;
  className: any;
  isSticky: boolean = false;

  @HostListener('window:scroll', [])
  onWindowScroll() {
    const banners = ['subscriber-banner-desktop', 'subscriber-banner-mobile'];

    banners.forEach(bannerId => {
      const bannerElement = document.getElementById(bannerId);
      if (bannerElement) {
        const offset = bannerElement.offsetTop;
        this.isSticky = window.pageYOffset > offset;
      }
    });
  }

  constructor(
    public router: Router,
    private testService: TestService,
    private changeRef: ChangeDetectorRef,
    private _authInterceptor: AuthInterceptor,
    private authService: AuthService,
    private userService: UserService,
    private roleService: RoleService,
    private selectedSubscriberService: SelectedSubscriberService,
    private route: Router
  ) {
    super();

    this.currentUser = this.userService?.user;

    this.subscriptions.add(
      merge(
        this.selectedSubscriberService.getUserDataObservable(),
        this.userService.getUserDataObservable()
      ).subscribe((userData) => {

        const subscribeId = JSON.parse(localStorage.getItem('patientId'))
        const currentUserId = JSON.parse(localStorage.getItem('UUID'))

        if (userData.id === subscribeId) {
          this.userData = userData;
        }

        if (currentUserId === subscribeId) {
          this.currentUser = this.userService?.user;
          this.userRole = this.roleService.isProvider
            ? 2001
            : this.roleService.isSubscriber
              ? 1001
              : this.roleService.isAdmin
                ? 3001
                : null;
        }

        this.onUserDataChanged(userData);
      })
    );

    testService
      .getTranslation('menus.completeAnalyteProfile')
      .then((res: string) => {
        this.message = new BehaviorSubject(res);
      });

    $('body').click((e) => {
      if (this.navOpen) {
        if (e.target.innerHTML !== `☰`) {
          this.closeNav();
        }
      }
    });

    this.testService.patient.subscribe((event) => {
      if (event === 'ptIdSet') {
        this.patientId = localStorage.getItem('patientId');
        this.currentPatient = JSON.parse(localStorage.getItem('patient'))
          ? JSON.parse(localStorage.getItem('patient'))
          : {};
      }
    });

    if (this.userData?.primaryRole === 4001) this.isGuestUser = true;

    testService.guestUser.subscribe(() => {
      this.currentPatient = JSON.parse(localStorage.getItem('patient'))
        ? JSON.parse(localStorage.getItem('patient'))
        : {};
      this.isGuestUser = true;
    });
    this.testService.removePatient.subscribe(() => {
      localStorage.removeItem('patientId');
      localStorage.removeItem('patient');
      this.currentPatient = {};
      this.patientId = null;
    });
  }

  get isProvider(): boolean {
    return this.roleService.isProvider;
  }

  get isSubscriber(): boolean {
    return this.roleService.isSubscriber;
  }

  get isAdmin(): boolean {
    return this.roleService.isAdmin;
  }

  setNavItems() {
    this.navItems = [
      {
        label: 'Home',
        route: 'home',
        show: !this.isLoggedin && !this.isGuestUser,
      },
      {
        label: 'My Subscribers',
        route: 'my-subscribers',
        class: 'my-subscribers',
        show: this.isLoggedin && (this.isProvider || this.isAdmin),
        disabled: !this.isProvider,
      },
      {
        label: 'My Accounts',
        route: 'my-accounts',
        class: 'my-accounts',
        show: this.isLoggedin && this.isSubscriber && !this.isProvider,
      },
      {
        label: 'Dashboard',
        route: 'dashboard',
        class: 'dashboard',
        show: this.isGuestUser || (this.isLoggedin && !this.isAdmin),
        disabled:
          (this.isProvider || this.isAdmin) && this.patientId === 'null',
        children: [
          {
            label: 'Individual Report',
            icon: 'insert_chart_outlined',
            action: 'individual',
            show: true,
          },
          {
            label: 'Trends Graph',
            icon: 'insert_chart',
            action: 'graph',
            param: 'true',
            show: true,
          },
          {
            label: 'Tabular Report',
            icon: 'article',
            action: 'table',
            show: true,
          },
          {
            label: 'Folding Plot',
            icon: 'insights',
            action: 'folding-plots',
            show: true,
          },
        ],
      },
      {
        label: 'Follow-up Questions',
        route: 'followups',
        class: 'followups',
        hide: !config.featureFlags.isFollowUpQuestionnaire,
        show: this.isGuestUser || (this.isLoggedin && !this.isAdmin),
        disabled:
          (this.isProvider || this.isAdmin) && this.patientId === 'null',
      },
      {
        label: 'Events',
        route: 'events',
        class: 'events',
        show: this.isLoggedin,
        disabled:
          (this.isProvider || this.isAdmin) && this.patientId === 'null',
      },
      {
        label: 'Kit Management',
        route: 'kit/register',
        class: 'register',
        show: this.isLoggedin,
        disabled:
          (this.isProvider || this.isAdmin) && this.patientId === 'null',
      },
      {
        label: this.isProvider ? 'Subscriber Profile' : 'Profile',
        route: 'account/subject',
        class: 'subject',
        show: this.isLoggedin && !this.isAdmin,
        disabled:
          (this.isProvider || this.isAdmin) && this.patientId === 'null',
      },
      {
        label: 'Questionnaire',
        route: 'questionnaire',
        class: 'questionnaire',
        hide: !config.featureFlags.isFollowUpQuestionnaire,
        show: this.isGuestUser || (this.isLoggedin && !this.isAdmin),
        disabled:
          (this.isProvider || this.isAdmin) && this.patientId === 'null',
      },
      {
        label: 'Test Data',
        route: 'test-data',
        class: 'test-data',
        show: environment.enableTestHelper,
      },
    ];

    this.mobileNavItems = [
      {
        action: () => {
          this.goToLink();
          this.doRouting(['/home']);
          this.closeNav();
        },
        route: 'home',
        class: 'home',
        condition: !this.isLoggedin && !this.isGuestUser,
        iconClass: 'fa fa-home mr-1',
        label: 'menus.home',
      },
      {
        action: () => {
          this.goToLink();
          this.doRouting(['/my-subscribers']);
          this.closeNav();
        },
        route: 'my-subscribers',
        class: 'my-subscribers',
        condition: this.isLoggedin && (this.isProvider || this.isAdmin),
        iconClass: 'fa fa-notes-medical mr-1',
        label: this.isProvider ? 'menus.myPatients' : 'menus.selectPatients',
      },
      {
        action: () => {
          this.goToLink();
          this.doRouting(['/my-accounts']);
          this.closeNav();
        },
        class: 'my-accounts',
        route: 'my-accounts',
        condition: this.isLoggedin && this.isSubscriber && !this.isProvider,
        iconClass: 'fa fa-user mr-1',
        label: 'My Accounts',
      },
      {
        action: () => {
          this.goToLink();
          this.doRouting(['/dashboard']);
          this.closeNav();
        },
        class: 'dashboard',
        route: 'dashboard',
        condition: this.isGuestUser || (this.isLoggedin && !this.isAdmin),
        iconClass: 'fas fa-tachometer-alt mr-1',
        label: 'menus.dashboard',
      },
      {
        action: () => {
          this.closeNav();
          this.doRouting(['/events']);
        },
        class: 'events',
        route: 'events',
        condition: this.isLoggedin,
        iconClass: 'fas fa-calendar-alt mr-1',
        label: 'menus.events',
      },
      {
        action: () => {
          this.closeNav();
          this.doRouting(['/kit/register']);
        },
        class: 'register',
        route: 'kit/register',
        condition: this.isLoggedin,
        iconClass: 'fas fa-medkit mr-1',
        label: 'menus.kitManagement',
      },
      {
        action: () => {
          this.doRouting(['/account/subject']);
          this.closeNav();
        },
        class: 'subject',
        route: 'account/subject',
        condition: this.isLoggedin && !this.isAdmin,
        iconClass: 'fas fa-user mr-1',
        label:
          this.isProvider || this.isAdmin
            ? 'menus.patientProfile'
            : 'menus.profile',
      },
      {
        action: () => {
          this.closeNav();
          this.goToLink();
          this.doRouting(
            this.isProvider ? ['/account/clinician'] : ['/account/manager']
          );
        },
        class: 'clinician',
        route: 'clinician',
        condition: this.isLoggedin && (this.isProvider || this.isAdmin),
        iconClass: 'fas fa-user-md mr-1',
        label: 'menus.myDetails',
      },
      {
        action: () => {
          this.doRouting(['/test-profiles']);
          this.closeNav();
        },
        class: 'test-profiles',
        route: 'test-profiles',
        condition: this.isAdmin,
        iconClass: 'fas fa-list-alt mr-1',
        label: 'menus.manageTestProfiles',
      },
      {
        action: () => {
          this.downloadHelpPdf();
          this.closeNav();
        },
        class: 'help',
        label: 'menus.help',
        iconClass: 'fas fa-question-circle mr-1',
        condition: true,
      },
      {
        action: () => {
          this.doRouting(['/about']);
          this.closeNav();
        },
        class: 'about',
        route: 'about',
        label: 'menus.about',
        iconClass: 'fas fa-info-circle mr-1',
        condition: true,
      },
      {
        action: () => {
          this.logout(true);
          this.closeNav();
        },
        class: 'logout',
        label: 'menus.logOut',
        iconClass: 'fas fa-sign-out-alt mr-1',
        condition: this.isLoggedin,
      },
      {
        action: () => {
          this.closeNav();
        },
        class: 'login',
        label: 'menus.logIn',
        iconClass: 'fas fa-sign-in-alt mr-1',
        condition: !this.isLoggedin,
      },
    ];
  }

  generateNewReport() {
    // This feature will extend from dashboard
  }

  onUserDataChanged(userData: User) {
    this.isLoggedin = true;
    this.setNavItems();
    const userRole = userData?.primaryRole;
    if (!userRole) {
      return;
    }

    this.currentPatient = JSON.parse(localStorage.getItem('patient'));
    localStorage.setItem('adaptiveView', 'true');
  }

  async gotoReports(target) {
    switch (target) {
      case 'graph':
        target = '/new-report/' + target + '/' + this.currentDonutView;
        this.route.navigate([target]);
        break;
      case 'table':
        target = '/new-report/' + target + '/' + this.currentDonutView;
        this.route.navigate([target]);
        break;
      case 'individual':
        target = '/new-report/' + target + '/' + this.currentDonutView;
        this.route.navigate([target]);
        break;
      case 'folding-plots':
        this.route.navigate(['/' + target]);
        break;
      case 'dashboard':
        this.route.navigate(['/' + target]);
        break;
      default:
        this.route.navigate(['/' + target]);
        break;
    }
  }

  langChanged() {
    this.ngAfterViewInit();
    this.childVisible = false;
    this.changeRef.detectChanges();
    setTimeout(() => {
      this.childVisible = true;
      this.changeRef.detectChanges();
    }, 1000);
  }

  ngAfterViewInit() {
    this.testService
      .getTranslation('menus.completeAnalyteProfile')
      .then((res: string) => {
        this.message.next(res);
      });
  }

  async ngOnInit() {
    $('#languageSwitcher').on('click', (e) => {
      e.stopPropagation();
    });
    this.setActiveTab(this.router.url);

    this.subscriptions.add(
      this.router.events
        .pipe(filter((event) => event instanceof NavigationEnd))
        .subscribe((event: NavigationEnd) => {
          this.setActiveTab(event.url);
        })
    );
  }

  private setActiveTab(fullUrl: string) {
    const url =
      fullUrl.split('/').reverse()[0] ||
      fullUrl.split('?').reverse()[0] ||
      'dashboard';
    const path = url.includes('?') ? url.split('?')[0] : url;
    setTimeout(() => {
      $('.navbar-nav .nav-item').removeClass('active');
      $('.navbar-nav .nav-item .dropdown-item').removeClass('active');
      $('.sidenav li.list-group-item').removeClass('active');
      if (path !== '/') {
        $('.' + path).addClass('active');
      }
    });
  }

  async logout(status?) {
    await this.authService.logOut();

    try {
      try {
        if (!status) {
          Promise.all([
            this.testService.getTranslation('messages.sessionError'),
            this.testService.getTranslation('common.okBtnText'),
          ]).then((result) => {
            this._authInterceptor
              .displayLogoutAlert(result[0] as string, '', result[1] as string)
              .then((success) => {
                this.isLoggedin = false;
                this.currentPatient = {};
              })
              .catch((error) => {
                console.error(' ERROR ', error);
              });
          });
        } else {
          localStorage.clear();
          this.isLoggedin = false;
          this.currentPatient = {};
        }
      } catch (error) {
        console.error(error);
      }
    } catch (error) {
      console.error(error);
    }
  }

  goToLink(route?, element?) {
    if (route && route === 'dashboard') {
      setTimeout(() => {
        const elements = document.getElementsByClassName('dashboard');
        for (var i = 0; i < elements.length; i++) {
          elements[i].classList.add('active');
        }
      }, 1000);
    }

    if ($(window).width() < 768) $('.navbar-toggler').trigger('click');
  }

  doRouting(path) {
    this.testService.doRouting(path);
  }

  openNav() {
    setTimeout(() => {
      if (!this.navOpen) {
        document.getElementById('mySidenav').style.right = '0';
        this.navOpen = true;
      }
    }, 50);
  }

  closeNav() {
    document.getElementById('mySidenav').style.right = '-280px';
    this.navOpen = false;
  }

  goTo(type) {
    const navExtras: NavigationExtras = {
      queryParams: { route: type },
    };

    this.testService.doRouting(['auth'], navExtras);
  }

  profileSeceltionChanged(event) {
    if (event.value !== 'null') {
      const profileIndex = this.profiles.findIndex(
        (profile) => profile.id === event.value
      );
      if (profileIndex !== -1)
        this.selectedProfile = this.profiles[profileIndex];
    } else {
      this.selectedProfile = {
        viewValue: 'Complete Test Profile',
        analytes: [],
        id: 'null',
      };
      this.testService
        .getTranslation('menus.completeAnalyteProfile')
        .then((res: string) => {
          this.message.next(res);
        });
    }
    this.testService.setTestProfile(this.selectedProfile);
  }

  downloadHelpPdf() {
    window.open('/assets/Algocyte Wellness v1.0 Instructions for use.pdf');
  }

  downloadQuickStartGuide() {
    window.open('/assets/Algocyte Wellness v1.0 - Quick Start Guide.pdf');
  }

  isDisabledProfileChange() {
    if (this.router.url === '/dashboard') {
      const adaptiveView =
        JSON.parse(localStorage.getItem('adaptiveView')) || false;
      if (adaptiveView) {
        const selectedProfile: any = JSON.parse(
          localStorage.getItem('testProfile')
        );
        if (
          selectedProfile &&
          selectedProfile.viewValue !== 'Complete Test Profile'
        ) {
          this.selectedProfile.id = 'null';
          const event = { value: this.selectedProfile.id };
          this.profileSeceltionChanged(event);
        }
        return true;
      } else {
        const isDashboardLoading = localStorage.getItem('isDashboardLoading');
        switch (isDashboardLoading) {
          case null:
            localStorage.setItem('isDashboardLoading', 'yes');
            return true;
          case 'yes':
            return true;
          case 'no':
            return false;
          default:
            break;
        }
      }
    } else {
      return false;
    }
  }

  hideProfileSelection() {
    if (this.isGuestUser || this.userData?.primaryRole === 3001) {
      return false;
    } else {
      return config.featureFlags.isSelectTestProfile;
    }
  }

  isSelectedProfileDisplay() {
    if (this.router.url === '/about') {
      return false;
    } else if (
      (this.userData.isProfileComplete && this.isLoggedin) ||
      this.isGuestUser
    ) {
      return true;
    } else {
      return false;
    }
  }

  displayValueInBanner(key) {
    if (!this.canDisplayPatientBanner()) {
      return key === 'dateOfBirth' ? false : '-';
    }

    switch (key) {
      case 'name':
        const { firstName, lastName } = this.userData?.userProfile || {};
        return firstName && lastName ? `${firstName} ${lastName}` : '-';
      case 'patientId':
        return this.userData?.userProfile?.patientId || '-';
      case 'dateOfBirth':
        return !!this.userData?.userProfile?.dateOfBirth;
      default:
        return '-';
    }
  }

  canDisplayPatientBanner() {
    return (!this.isProvider || localStorage.getItem('UUID') !== localStorage.getItem('patientId'));
  }

  isMenuActive(pageRoute: string, status?: string, item?): string {
    const routeName = this.router?.url.slice(1);

    if (!pageRoute || !routeName) {
      return "";
    }

    switch (status) {
      case 'child':
        return routeName.includes(pageRoute) ? "active" : "";

      case 'dashboard':
        const anyChildOpen = item?.children.some(child => routeName.includes(child.action));
        const dashboardElement = document.getElementById('dashboard');
        if (dashboardElement) {
          if (anyChildOpen) {
            dashboardElement.classList.add('active');
          } else {
            dashboardElement.classList.remove('active');
          }
        }
        return anyChildOpen ? "active" : "";

      default:
        return pageRoute === routeName.toString() ? "active" : "";
    }
  }

  isAnyChildMenuRouteOpen(item) {
    if (item.route == 'dashboard') {
      return;
    }
    else {
      const routeName = this.router?.url.slice(1).toString();
      if (
        routeName !== item.route &&
        !item.children.some((element) => routeName.includes(element.action))
      ) {
        this.goToLink();
        this.doRouting([item.route]);
      }
    }
  }
}
