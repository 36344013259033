import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  OnInit,
} from '@angular/core';
import Chart from 'chart.js/auto';
import { config } from 'src/app/config';

declare var $: any;

@Component({
  selector: 'app-donut',
  templateUrl: './donut.component.html',
  styleUrls: ['./donut.component.css'],
})
export class DonutComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() donutData: any;
  chartObject: any;
  chartData: any;
  chartId: string = '';
  allRanges: any;
  userRole = JSON.parse(localStorage.getItem('roleId'));
  rangeData: any;
  analyteMapping: any;
  xPosition;
  yPosition;
  labelCenterX: any;
  labelCenterY: any;
  textWidth: any;
  viewDetailsFontSize: any = 11;
  options: any;
  thickness: any;
  analyteSequence: any = [];
  commonStyles = {
    'background-color': 'rgba(0, 0, 0, 0.7)',
    opacity: 1,
    color: 'white',
    'border-radius': '3px',
    '-webkit-transition': 'all 0.1s ease',
    transition: 'all 0.1s ease',
    'pointer-events': 'none',
  };

  constructor() { }

  ngOnInit() {
    $(document).ready(() => {
      $('body').click(() => {
        $('.desc-immunescore').css({
          display: 'none',
        });
      });
    });
    $('.offset-md-3.col-md-6.col-sm-12').mousemove((e) => {
      this.xPosition = e.pageX;
      this.yPosition = e.pageY;
    });

    this.setChartData();
  }

  ngAfterViewInit(): void {
    this.renderDonutChart(this.chartId);
  }

  ngOnChanges() {
    this.setChartData();
  }

  setChartData() {
    if (!this.donutData) {
      this.handleNoDataFound();
      return;
    }

    this.updateChartData();
    this.updateChartObject();
    this.updateAdditionalData();
  }

  handleNoDataFound() {
    const chartElement = document.getElementById(this.chartId);
    if (chartElement) {
      chartElement.innerHTML = 'No Data Found';
    }
  }

  updateChartData() {
    const {
      immuneScore,
      chartId,
      datasets,
      labels,
      rangeData,
      analyteMapping,
      analyteSequence,
      allRanges,
    } = this.donutData;
    this.analyteSequence = this.sortArrayBySequence(config.analyteSequenceArray, analyteSequence)
    this.options = this.getDonutOptions(immuneScore);
    this.thickness = this.getDonutThickness();
    this.chartId = chartId;
    this.chartData = { datasets, labels };
  }

  sortArrayBySequence(sequence: string[], arrayToSort: string[]): string[] {
    const sequenceMap = new Map<string, number>();
    sequence.forEach((item, index) => sequenceMap.set(item, index));

    return arrayToSort.sort((a, b) => {
      const indexA = sequenceMap.get(a);
      const indexB = sequenceMap.get(b);

      return (indexA !== undefined ? indexA : Number.MAX_SAFE_INTEGER) -
        (indexB !== undefined ? indexB : Number.MAX_SAFE_INTEGER);
    });
  }

  updateChartObject() {
    if (!this.chartObject) return;
    const { labels, datasets, immuneScore } = this.donutData;
    this.chartObject.data.labels = labels;
    this.chartObject.data.datasets = datasets;
    this.chartObject.options = {
      ...this.options,
      elements: {
        ...this.options.elements,
        center: {
          ...this.options.elements.center,
          text: immuneScore,
        },
      },
    };
    this.chartObject.update();
  }

  updateAdditionalData() {
    const { rangeData, analyteMapping, analyteSequence, allRanges } =
      this.donutData;
    this.rangeData = rangeData;
    this.analyteMapping = analyteMapping;
    this.analyteSequence = analyteSequence;
    this.allRanges = allRanges;
  }

  renderDonutChart(chartId) {
    let element = document.getElementById(chartId);
    if (element !== null) element = null;
    const ctx = (
      document.getElementById(chartId) as HTMLCanvasElement
    ).getContext('2d');

    this.chartObject = new Chart(ctx, {
      type: 'doughnut',
      plugins: [this.thickness],
      data: this.chartData,
      options: this.options,
    });
  }

  openViewDetailsModal() {
    $('.desc-immunescore').css({
      display: 'block',
    });

    if (this.isMobileDevice()) {
      // Mobile device
      document.getElementById('desc-immunescore-focus').scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      });
    } else {
      window.scrollTo(0, 0);
    }
  }

  isMobileDevice() {
    const deviceType = this.detectDeviceType();

    switch (deviceType) {
      case 'mobile':
        return true;
        break;
      case 'tablet':
        return false;
        // Code for tablet view
        break;
      case 'desktop':
        return false;
        // Code for desktop view
        break;
    }
  }

  isMobileView() {
    const mobileThreshold = 768;
    return window.innerWidth < mobileThreshold;
  }

  detectDeviceType() {
    const mobileThreshold = 768;
    const tabletThreshold = 1024;
    const isMobile = /Mobi|Android/i.test(navigator.userAgent);
    const isTablet =
      window.innerWidth >= mobileThreshold &&
      window.innerWidth < tabletThreshold;

    if (isMobile) {
      if (window.innerWidth < mobileThreshold) {
        return 'mobile';
      } else if (isTablet) {
        return 'tablet';
      } else {
        return 'desktop';
      }
    } else {
      if (window.innerWidth < mobileThreshold) {
        return 'mobile';
      } else if (isTablet) {
        return 'tablet';
      } else {
        return 'desktop';
      }
    }
  }

  getDonutThickness() {
    return {
      id: 'thickness',
      beforeDraw: (chart, options) => {
        if (this.donutData.donutChartData === 'Haematology') {
          this.getDountLabel(chart, chart.config.options.elements.center, 0);
          this.getDountLabel(chart, chart.config.options.elements.bottom, 20);
        }

        let thickness = chart.options.plugins.thickness.thickness;
        thickness.forEach((item, index) => {
          chart.getDatasetMeta(0).data[index].innerRadius = item[0];
          chart.getDatasetMeta(0).data[index].outerRadius = item[1];
        });
      },
    };
  }

  getDountLabel = (chart, config, extraVerticalSpace) => {
    const ctx = chart.ctx;
    const centerConfig = config;
    const fontStyle = centerConfig.fontStyle || 'Arial';
    const fontSize = centerConfig.fontSize || '50px';
    const txt = centerConfig.text;
    const color = centerConfig.color || '#000';
    const sidePadding = centerConfig.sidePadding || 20;
    const sidePaddingCalculated = (sidePadding / 100) * (chart.innerRadius * 2);
    ctx.font = fontSize + ' ' + fontStyle;

    const stringWidth = ctx.measureText(txt).width;
    if (txt === 'View Details') this.textWidth = stringWidth;
    const elementWidth = chart.innerRadius * 2 - sidePaddingCalculated;

    const widthRatio = elementWidth / stringWidth;
    const newFontSize = Math.floor(fontSize * widthRatio);
    const elementHeight = chart.innerRadius * 2;

    const fontSizeToUse = Math.min(newFontSize, elementHeight);

    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';
    ctx.id = centerConfig.id;
    this.labelCenterX = (chart.chartArea.left + chart.chartArea.right) / 2;
    this.labelCenterY =
      (chart.chartArea.top + chart.chartArea.bottom) / 2 + extraVerticalSpace;

    ctx.fillStyle = color;
    // Draw text in center
    ctx.fillText(txt, this.labelCenterX, this.labelCenterY);
  };

  generateAnalyteData = (analyte, group, color, range, rangeUnit) => {
    const analyteValue = Number(this.rangeData[group]['value'][analyte]).toFixed(2);
    const analyteMapping = this.analyteMapping[analyte];
    const isMobile = this.isMobileDevice();
    if (analyteValue != 'NaN') {

      if (isMobile) {
        return `<tr>
        <td style='white-space: nowrap !important; width: 1% !important; padding-top: 0.45em !important; padding-bottom: 0.45em !important;'> Value </td>
        <td style='white-space: nowrap !important; width: 1% !important; padding-top: 0.45em !important; padding-bottom: 0.45em !important;'> ${analyteValue} </td>
      </tr>
      <tr>
        <td style='white-space: nowrap !important; width: 1% !important; padding-top: 0.45em !important; padding-bottom: 0.45em !important;'> Lab Ranges </td>
        <td style='white-space: nowrap !important; width: 1% !important; padding-top: 0.45em !important; padding-bottom: 0.45em !important;'> ${range} ${rangeUnit} </td>
      </tr>`;
      } else {
        return `<tr>
        <td style='white-space: nowrap !important; width: 1% !important; padding-top: 0.45em !important; padding-bottom: 0.45em !important;'>
          <img src="./../../../../assets/dashboard/circle-solid-${color}.svg" style="width: 10px; height: 10px;">
        </td>
        <td style='white-space: nowrap !important; width: 1% !important; padding-top: 0.45em !important; padding-bottom: 0.45em !important;'> 
          ${analyteMapping} (${analyte}) 
        </td>
        <td style='white-space: nowrap !important; width: 1% !important; padding-top: 0.45em !important; padding-bottom: 0.45em !important;'> 
          ${analyteValue}
        </td>
        <td style='white-space: nowrap !important; width: 1% !important; padding-top: 0.45em !important; padding-bottom: 0.45em !important;'> 
          ${range} ${rangeUnit} 
        </td>
      </tr>`;
      }
    }
    return ""
  };

  generateEmptyRowForMobile = () => {
    return `<tr style="border: white 1px solid;">
    <td colspan="2" style="padding: 0px;">
    </td>
  </tr>`;
  };

  getData = (analytes, tableHead, group, color) => {
    const isMobile = this.isMobileDevice();
    const analyteValue = this.rangeData[group]['value'][analytes];
    const range = this.allRanges['referenceRange'][analytes] || '--';
    const rangeUnit = this.allRanges['referenceUnit'][analytes] || '';
    const analyteMapping = this.analyteMapping[analytes];
    if (typeof analyteValue == 'string' || analyteValue === -1 || analyteValue == 'NaN') {
      return '';
    }

    let tableData = '';
    if (analyteValue) {
      tableHead.forEach((head, i) => {
        if (isMobile) {
          if (i === 0) {
            tableData += `
          <tr>
            <td colspan="2" style='white-space: nowrap !important; width: 1% !important; padding-top: 0.45em !important; padding-bottom: 0.45em !important;'>
              <span>
                <img src="./../../../../assets/dashboard/circle-solid-${color}.svg" style="width: 10px; height: 10px;">
                <span style="padding-left: 5px;">${analyteMapping} (${analytes})</span>
              </span>
            </td>
          </tr>`;
          } else if (i === 1) {
            tableData += this.generateAnalyteData(analytes, group, color, range, rangeUnit);
          }
        } else {
          switch (i) {
            case 0:
              tableData += `
            <td style='white-space: nowrap !important; width: 1% !important; padding-top: 0.45em !important; padding-bottom: 0.45em !important;'>
              <img src="./../../../../assets/dashboard/circle-solid-${color}.svg" style="width: 10px; height: 10px;">
            </td>`;
              break;
            case 1:
              tableData += `
            <td style='white-space: nowrap !important; width: 1% !important; padding-top: 0.45em !important; padding-bottom: 0.45em !important;'>
              ${analyteMapping} (${analytes})
            </td>`;
              break;
            case 2:
              tableData += `
            <td style='white-space: nowrap !important; width: 1% !important; padding-top: 0.45em !important; padding-bottom: 0.45em !important;'>
              ${Number(analyteValue).toFixed(2)}
            </td>`;
              break;
            case 3:
              tableData += `
            <td style='white-space: nowrap !important; width: 1% !important; padding-top: 0.45em !important; padding-bottom: 0.45em !important;'>
              ${range} ${rangeUnit}
            </td>`;
              break;
            default:
              break;
          }
        }
      });
    }

    if (isMobile) {
      tableData += this.generateEmptyRowForMobile();
    }

    return tableData;
  };

  externalTooltipHandler = (context) => {
    const { chart, tooltip } = context;
    const tooltipEl = this.getTooltip(chart);

    if (tooltip.opacity === 0) {
      tooltipEl.style.opacity = 0;
      return;
    }

    tooltipEl.classList.remove('above', 'below', 'no-transform');

    if (tooltip.yAlign) tooltipEl.classList.add(tooltip.yAlign);
    else tooltipEl.classList.add('no-transform');

    function getBody(bodyItem) {
      return bodyItem.lines;
    }

    let hoveredSectionColor = '';
    if (tooltip.body) {
      const titleLines = tooltip.title || [];
      const bodyLines = tooltip.body.map(getBody);
      let group = bodyLines.length ? bodyLines[0][0].split(':')[0] : null;
      hoveredSectionColor = group.toLowerCase();
      group = group.toLowerCase();
      const tableHead = ['', 'Test Result', 'Value', 'Range'];
      let innerHtml = '<thead><tr>';
      tableHead.forEach((title, index) => {
        if (!this.isMobileDevice()) innerHtml += '<th>' + title + '</th>';
      });

      innerHtml += '</tr></thead><tbody>';

      const labels = Object.keys(this.rangeData[group].value);
      function sortArrayBasedOnSequence(array, sequence) {
        const filteredArray = array.filter((item) => sequence.includes(item));

        const sortedArray = filteredArray.sort((a, b) => {
          const indexA = sequence.indexOf(a);
          const indexB = sequence.indexOf(b);
          return indexA - indexB;
        });

        return sortedArray;
      }
      const c = sortArrayBasedOnSequence(labels, this.analyteSequence);
      c.forEach((body, i) => {
        if (this.isMobileDevice()) {
          innerHtml += this.getData(
            body,
            tableHead,
            group,
            hoveredSectionColor
          );
        } else {
          innerHtml += `<tr>${this.getData(
            body,
            tableHead,
            group,
            hoveredSectionColor
          )}</tr>`;
        }
      });

      innerHtml += '</tbody>';
      switch (hoveredSectionColor) {
        case 'green':
        case 'yellow':
        case 'red':
          $('#chartjs-tooltip table').css(this.commonStyles);
          break;
        default:
          break;
      }

      const tableRoot = tooltipEl.querySelector('table');
      tableRoot.innerHTML = innerHtml;
    }

    const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

    tooltipEl.style.opacity = 1 as any;
    tooltipEl.style.display = 'block';
    tooltipEl.style.zIndex = 100 as any;
    tooltipEl.style.position = 'absolute';
    tooltipEl.style.left = 15 + 'px';
    tooltipEl.style.right = 15 + 'px';
    tooltipEl.style.top = positionY + 'px';
    tooltipEl.style.fontFamily = tooltip._bodyFontFamily;
    tooltipEl.style.fontSize = tooltip.bodyFontSize + 'px';
    tooltipEl.style.fontStyle = tooltip._bodyFontStyle;
    tooltipEl.style.padding =
      tooltip.yPadding + 'px ' + tooltip.xPadding + 'px';
    tooltipEl.style.pointerEvents = 'none';
  };

  getTooltip = (chart) => {
    const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;
    let tooltipEl = chart.canvas.parentNode.querySelector('div');
    if (!tooltipEl) {
      tooltipEl = this.createTooltip(positionX, positionY, chart, tooltipEl);
    }

    return tooltipEl;
  };

  createTooltip(positionX: number, positionY: number, chart, tooltipEl): void {
    tooltipEl = document.createElement('div');
    tooltipEl.id = 'chartjs-tooltip';
    tooltipEl.style.minWidth = this.isMobileDevice() ? '250px' : 'auto';
    tooltipEl.style.maxWidth = this.isMobileDevice() ? '340px' : 'auto';
    tooltipEl.classList.add('table', 'w-auto', 'bottom');
    tooltipEl.style.opacity = '1';
    tooltipEl.style.pointerEvents = 'none';
    tooltipEl.style.position = 'absolute';
    tooltipEl.style.transform = `translate(${positionY}%,${positionX}%)`;
    tooltipEl.style.transition = 'all .1s ease';

    const table = document.createElement('table');
    table.style.minWidth = this.isMobileDevice() ? '250px' : '580px';
    table.style.maxWidth = this.isMobileDevice() ? '340px' : '800px';
    table.style.margin = '0px';

    tooltipEl.appendChild(table);
    chart.canvas.parentNode.appendChild(tooltipEl);
    return tooltipEl;
  }

  getDonutOptions(immuneScore) {
    const donutTextPlugin = {
      id: 'donutText',
      afterDraw: this.drawDonutText,
    };

    Chart.register(donutTextPlugin);

    return {
      plugins: this.getPluginOptions(),
      bezierCurve: false,
      animation: false,
      responsive: true,
      title: {
        display: false,
        text: 'Immune Score',
      },
      elements: this.getElementOptions(immuneScore),
      onClick: this.handleClick,
    };
  }

  drawDonutText(chart) {
    const {
      ctx,
      config,
      chartArea: { top, right, bottom, left },
    } = chart;
    const centerConfig = config.options.elements?.center;
    const bottomConfig = config.options.elements?.bottom;
    const centerX = (left + right) / 2;
    const centerY = (top + bottom) / 2;

    const drawText = (config, y) => {
      ctx.save();
      ctx.font =
        config.fontWeight + ' ' + config.fontSize + ' ' + config.fontStyle;
      ctx.fillStyle = config.color;
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.marginTop = '20px';
      ctx.fillText(config.text, centerX, y);
      ctx.restore();
    };

    if (centerConfig) {
      drawText(centerConfig, centerY);
    }

    if (bottomConfig) {
      const fontSize = parseInt(bottomConfig.fontSize, 10);
      const spaceBelowCenter = centerY + fontSize + 5;
      drawText(bottomConfig, spaceBelowCenter);
    }
  }

  getThickness() {
    const deviceType = this.detectDeviceType();
    switch (deviceType) {
      case 'mobile':
        return [
          [75, 93],
          [75, 93],
          [75, 93],
        ];
      case 'tablet':
        return [
          [45, 58],
          [45, 58],
          [45, 58],
        ];
      case 'desktop':
        return [
          [50, 63],
          [50, 63],
          [50, 63],
        ];
    }
  }

  getPluginOptions() {
    return {
      thickness: {
        thickness: this.getThickness(),
      },
      legend: false,
      tooltip: {
        enabled: false,
        position: 'nearest',
        external: this.externalTooltipHandler,
      },
    };
  }

  getElementOptions(immuneScore) {
    return {
      center: {
        id: 'scoreText',
        text: immuneScore,
        color: '#333',
        fontStyle: 'Open-Sans',
        fontSize: '20px',
        fontWeight: 'bold',
        fontStretch: 'normal',
        lineHeight: 1.36,
        letterSpacing: 'normal',
        textAlign: 'center',
        sidePadding: 60,
      },
      bottom: {
        id: 'viewDetailsText',
        text: 'View Details',
        color: '#a8a8a8',
        fontStyle: 'Open-Sans',
        fontSize: this.viewDetailsFontSize + 'px',
        fontWeight: 'bold',
        fontStretch: 'normal',
        lineHeight: 1.36,
        letterSpacing: 'normal',
        textAlign: 'center',
        sidePadding: 60,
      },
    };
  }

  handleClick(event) {
    this.getCursorPosition(document.getElementById(this.chartId), event);
  }

  getCursorPosition = (canvas, event) => {
    let xDiff: any = this.textWidth / 2;
    let yDiff: any = this.viewDetailsFontSize / 2;
    let xLeft = this.labelCenterX - xDiff;
    let xRight = this.labelCenterX + xDiff;
    let yTop = this.labelCenterY - yDiff;
    let yBottom = this.labelCenterY + yDiff;

    if (
      event.x > xLeft &&
      event.x < xRight &&
      event.y > yTop &&
      event.y < yBottom
    )
      this.openViewDetailsModal();
  };
}
