import { Component, OnInit } from '@angular/core';
import { forkJoin } from 'rxjs';
import * as moment from 'moment';

import { BaseComponent } from '../../modules/shared/base.component';
import { ResultSetService } from '../../services/resultSet/resultSet.service';
import {
  ResultSetResponse,
  ResultSetWithScores,
} from '../../services/resultSet/types';
import { UserService } from '../../services/user/user.service';
import { ScoreService } from '../../services/score/score.service';
import { ScoreResults } from '../../services/score/types';
import { HealthEventService } from '../../services/healthEvent/healthEvent.service';
import { HealthEvent, User } from '../../../types';
import { SelectedSubscriberService } from '../../services/selected-subscriber/selected-subscriber.service';
import { RoleService } from 'src/app/services/user/role.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
})
export class DashboardComponent extends BaseComponent implements OnInit {
  resultSets: ResultSetWithScores[] = [];
  events: HealthEvent[] = [];
  selectedSubscriber: User | null;
  isLoading = false;
  isInitialised = false;
  scores: ScoreResults;
  error = '';

  constructor(
    private readonly resultSetService: ResultSetService,
    private readonly healthEventService: HealthEventService,
    private readonly scoreService: ScoreService,
    private readonly userService: UserService,
    private readonly roleService: RoleService,
    private router: Router,
    private readonly selectedSubscriberService: SelectedSubscriberService
  ) {
    super();
    this.selectedSubscriberService.getUserDataObservable().subscribe((d) => {
      if (d) {
        this.selectedSubscriber = d;
        if (this.roleService.isProvider) {
          const currentUser = this.userService?.user;
          if (currentUser.id != this.selectedSubscriber.id) {
            this.getData();
          }
        } else {
          this.getData();
        }
      }
    });
  }

  ngOnInit() {
    this.router.navigate(['/new-report/individual/Haematology']);
  }

  getData() {
    this.isLoading = true;
    this.error = '';

    const userId = this.selectedSubscriber.id;

    this.subscriptions.add(
      forkJoin<ResultSetResponse[], ScoreResults, HealthEvent[]>([
        this.resultSetService.getResultSets$({ userId }),
        this.scoreService.getScores({ userId }),
        this.healthEventService.getHealthEvents({ userId }),
      ]).subscribe({
        next: ([resultSets, scores, events]) => {
          this.resultSets = this._mapScoresToResultSets(resultSets, scores);
          this.scores = scores;
          this.events = events;
          this.isLoading = false;
          this.isInitialised = true;
        },
        error: () => {
          this.isLoading = false;
          this.error =
            'Failed to fetch the health data. Please contact support';
        },
      })
    );
  }

  private _mapScoresToResultSets(
    resultSets: ResultSetResponse[],
    scores: ScoreResults
  ): ResultSetWithScores[] {
    return resultSets
      .map((set) => {
        set.results.analyteResults = set.results.analyteResults.filter(
          (x: any) => {
            if (x !== null) {
              const value =
                typeof x.analyteValue === 'string'
                  ? parseFloat(x.analyteValue)
                  : x.analyteValue;
              return typeof value === 'number' && !isNaN(value);
            }
            return false;
          }
        );

        const sortedNonAdaptive = scores.nonAdaptive.sort(
          (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
        );
        const nonAdaptiveScoreDto = sortedNonAdaptive.find(
          (x) => x.resultSetId === set.id
        );
        const adaptiveScores = scores.adaptive
          .filter((x) => x.resultSetId === set.id)
          .filter((score) => {
            const analyteResult = set.results.analyteResults.find(
              (res) => res.analyteKey === score.analyteKey
            );

            if (analyteResult) {
              score.value = Number(analyteResult.analyteValue);
              return true;
            }

            return false;
          })
          .map((score) => ({ ...score, date: set.results.observationDate }));

        return {
          ...set,
          nonAdaptiveScore: typeof nonAdaptiveScoreDto?.score == 'number' ? parseFloat((nonAdaptiveScoreDto?.score).toFixed(2)) : nonAdaptiveScoreDto?.score,
          observationDate: set.results.observationDate,
          adaptiveScores,
          essentialAnalytes: nonAdaptiveScoreDto?.essentialAnalytes || [],
        };
      })
      .sort((a, b) =>
        moment(a.observationDate, 'YYYY-MM-DD').diff(
          moment(b.observationDate, 'YYYY-MM-DD')
        )
      );
  }
}
