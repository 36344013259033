import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { TestService } from '../../services/test.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import * as moment from 'moment';
import Swal from 'sweetalert2';
import { config } from '../../config';
import { Router } from '@angular/router';
import { ApiService } from '../../services/api.service';
import { commonConfig } from '../../config/common/common';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import {
  AuthorisedByUser,
  AuthorisedUser,
  PagedQueryResponse,
  Provider,
  Subscriber,
  User,
} from 'src/types';
import { Subscription } from 'rxjs';
import { UserService } from 'src/app/services/user/user.service';
import { ProviderService } from 'src/app/services/provider/provider.service';
import { SelectedSubscriberService } from '../../services/selected-subscriber/selected-subscriber.service';
import { RoleService } from '../../services/user/role.service';
import { ResultSetService } from 'src/app/services/resultSet/resultSet.service';
import { ResultSetResponse } from 'src/app/services/resultSet/types';
import { ScoreService } from '../../services/score/score.service';
import { ScoreResults } from '../../services/score/types';

declare const $: any;

@Component({
  selector: 'app-my-patients',
  templateUrl: './my-patients.component.html',
  styleUrls: ['./my-patients.component.css'],
})
export class MyPatientsComponent implements OnInit {
  config = config;
  displayedColumns: string[] = [
    'firstName',
    'dateOfBirth',
    'patientId',
    'latestTestDate',
    'test_name',
    'action',
  ];
  dataSource: MatTableDataSource<any>;
  selection = new SelectionModel<any>(true, []);
  dispatchForm: FormGroup;
  dispatchQuestions = [];
  allRequests = [];
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  temp;
  selectedPatient = JSON.parse(localStorage.getItem('patient'))
    ? JSON.parse(localStorage.getItem('patient'))
    : {};
  statuses = new FormControl();
  userRole = JSON.parse(localStorage.getItem('roleId'));
  isManager = false;
  today = new Date(moment().utcOffset('+00:00').format('YYYY-MM-DD'));
  allQueries = [];
  allStatus = [];
  allClinicians: any[];
  patientNHS: FormControl = new FormControl('', [
    Validators.required,
    Validators.pattern('[0-9]{10}$'),
  ]);
  clinicianUUID: FormControl = new FormControl('', [Validators.required]);
  patientFound = false;
  retrivedPatient;
  isPatient: boolean;
  isClinician: boolean;
  myPatients = false;
  myAccounts = false;
  linkPatientError = '';
  patientOtp: FormControl = new FormControl('', [
    Validators.required,
    Validators.pattern('[0-9]{6}$'),
  ]);
  patientEmail: FormControl = new FormControl('', [
    Validators.required,
    Validators.pattern(commonConfig.pattern.email),
  ]);
  searchText;
  allSelectedForGender = false;
  allSelectedForAgeGroup = false;
  start_date_inclusive: any;
  end_date_inclusive: any;
  genders: any = [];
  ageGroups: any = commonConfig.ageGroups;
  @ViewChild('select') select: MatSelect;
  @ViewChild('select2') select2: MatSelect;
  @ViewChild('search') input: ElementRef;
  currentSort = { sortBy: 'resultSet.observationDate', sortOrder: 'desc' };
  selectedGenders;
  selectedAgeGroups;
  totalRecords = 0;
  limit: any = 7;
  pageNo: any = 0;
  currentUserId = localStorage.getItem('UUID');
  providerData: Provider | null;
  userData: User | null;
  selectedSubscriber: User | null;
  authorisedUsers = new MatTableDataSource<AuthorisedUser>();
  authorisedByUsers = new MatTableDataSource<AuthorisedByUser>();
  private subscription: Subscription = new Subscription();
  connectedAccounts: AuthorisedByUser[] = [];

  constructor(
    private _testService: TestService,
    private _apiService: ApiService,
    private ngxService: NgxUiLoaderService,
    public customPaginatorIntl: MatPaginatorIntl,
    private router: Router,
    private userService: UserService,
    private roleService: RoleService,
    private providerService: ProviderService,
    private selectedSubscriberService: SelectedSubscriberService,
    private readonly resultSetService: ResultSetService,
    private readonly scoreService: ScoreService,
  ) {
    this.initializing()
  }

  setPageAction(currentRoute) {
    switch (currentRoute) {
      case '/my-accounts':
        this.myAccounts = true;
        break;
      case '/my-subscribers':
        this.myPatients = true;
        break;
      default:
        break;
    }
  }

  onlyNumberKey(evt) {
    const ASCIICode = evt.which ? evt.which : evt.keyCode;
    if (ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57)) {
      return false;
    }
    return true;
  }

  ngOnInit() {
    this._testService
      .getTranslation('common.itemPerPage')
      .then((res: string) => {
        this.customPaginatorIntl.itemsPerPageLabel = res;
      });

    this.selectedPatient = JSON.parse(localStorage.getItem('patient'))
      ? JSON.parse(localStorage.getItem('patient'))
      : {};

    $('#claimPatient').on('shown.bs.modal', (e) => {
      $('#claimPatient').css('z-index', 999);
      $('.modal-backdrop').css('z-index', 998);
    });
  }

  async openPatientLinkingModal() {
    if (this.myAccounts) {
      $('#patientToPatientLink').modal('show');
      this.ngxService.stop();
    } else if (this.myPatients) {
      if (this.roleService.isAdmin || this.roleService.isProvider) {
        $('#claimPatient').modal('show');
      }
    }
  }

  isProvider(): boolean {
    return this.roleService.isProvider;
  }

  initEventForm(dispatchQuestions) {
    this.dispatchForm = new FormGroup(this.getControls(dispatchQuestions));
  }

  getControls(controlsArray) {
    const controls: any = {};
    controlsArray.forEach((control) => {
      controls[control.formControlName] = new FormControl(
        '',
        Validators.required
      );
    });
    controls.startDate = new FormControl();
    controls.endDate = new FormControl();
    return controls;
  }

  selectPatient(user) {
    this.selectedSubscriberService.setSelectedSubscriber(user.id);
  }

  clearPatientFromStorage(event?) {
    this.selectedSubscriberService.removeSelectedSubscriber();
    event.stopPropagation();
  }

  searchPatient() {
    this.linkPatientError = '';

    this.ngxService.start();
    if (this.myPatients && this.providerData) {
      const myOrganisationId = this.providerData.organisationId;
      this._apiService
        .findOrganisationSubscriberByPatientId(
          this.patientNHS.value,
          myOrganisationId
        )
        .subscribe(
          async ({ results: res }: PagedQueryResponse<Subscriber>) => {
            if (res && Array.isArray(res) && res.length) {
              this.patientFound = true;
              this.retrivedPatient = res[0];
              const {
                country,
                region,
                city,
                postalCode,
                firstLineAddress,
                secondLineAddress,
              } = this.retrivedPatient.profile?.address || {};
              this.retrivedPatient.address = [
                country,
                region,
                city,
                postalCode,
                firstLineAddress,
                secondLineAddress,
              ]
                .filter((addr) => addr)
                .join(', ');
            } else {
              const res = await Promise.all([
                this._testService.getTranslation('myPatient.notFoundPatient'),
                this._testService.getTranslation('common.okBtnText'),
              ]);
              Swal.fire({
                type: 'error',
                text: res[0] as string,
                timer: 5000,
                showConfirmButton: true,
                confirmButtonText: res[1] as string,
              });
            }
            this.ngxService.stop();
          },
          () => {
            this.ngxService.stop();
          }
        );
    } else if (this.myAccounts) {
      const userId = this.userData.id;
      const code = Number(this.patientOtp.value);

      this._apiService.useLinkingCode(userId, code).subscribe(
        (response: any) => {
          Promise.all([this._testService.getTranslation('common.okBtnText')])
            .then((keyResult: any) => {
              Swal.fire({
                type: 'success',
                text: 'Link created successfully',
                timer: 2000,
                showConfirmButton: true,
                confirmButtonText: keyResult[0],
              });
              this.toggleModal('patientToPatientLink', false);
              this.userService.fetchAuthorisedByUserData();
              this.ngxService.stop();
            })
            .catch((error) => {
              this.ngxService.stop();
            });
        },
        async (err) => {
          const text =
            await this._testService.getTranslation('common.okBtnText');
          Swal.fire({
            type: 'error',
            text: err.error.message,
            timer: 5000,
            showConfirmButton: true,
            confirmButtonText: text as string,
          });
          this.ngxService.stop();
        }
      );
    }
  }

  unlinkPatient() {
    this.ngxService.start();
    this._apiService
      .removeSubscriberFromProvider(
        this.retrivedPatient.userId,
        this.userData.id
      )
      .subscribe(
        (res) => {
          Promise.all([
            this._testService.getTranslation('messages.removed'),
            this._testService.getTranslation('messages.linkedRemoved'),
            this._testService.getTranslation('common.okBtnText'),
          ]).then((res: any[]) => {
            Swal.fire({
              type: 'success',
              title: res[0],
              text: res[1],
              showConfirmButton: true,
              confirmButtonText: res[2],
            });
          });

          this.ngxService.stop();

          if (
            this.selectedPatient &&
            this.selectedPatient.id === this.retrivedPatient.userId
          ) {
            this.selectedPatient = {};
            this.clearPatientFromStorage();
          }

          this.cancelFlow(true);
          this.ngOnInit();
        },
        (err) => {
          this.ngxService.stop();
        }
      );
  }

  async linkPatient() {
    this.linkPatientError = '';

    this.ngxService.start();
    try {
      await this.providerService
        .createLinkRequest(this.retrivedPatient.id)
        .catch((error) => {
          this.linkPatientError =
            error.error?.message || 'myPatient.linkPatientError';
          throw new Error(error);
        });
      Promise.all([
        this._testService.getTranslation('messages.linkedGenerated'),
        this._testService.getTranslation('messages.success'),
        this._testService.getTranslation('common.okBtnText'),
      ]).then((res: any[]) => {
        Swal.fire({
          type: 'success',
          title: res[0],
          text: res[1],
          showConfirmButton: true,
          confirmButtonText: res[2],
        });
      });
      this.ngxService.stop();
      this.cancelFlow(true);
    } catch (err) {
      this.ngxService.stop();
    }
  }

  cancelFlow(closeModal?: boolean) {
    this.linkPatientError = '';

    if (this.myPatients) {
      if (this.isManager) {
        this.clinicianUUID.reset();
      }
      this.patientNHS.reset();
      this.retrivedPatient = undefined;
      this.patientFound = false;
      if (closeModal) {
        $('#claimPatient').modal('hide');
      }
    } else if (this.myAccounts) {
      if (closeModal) {
        $('#patientToPatientLink').modal('hide');
      }
    }
  }

  getButtonDisablility() {
    if (this.isManager) {
      if (
        this.clinicianUUID.status === 'INVALID' ||
        this.patientNHS.status === 'INVALID'
      ) {
        return true;
      } else {
        return false;
      }
    } else if (!this.isManager) {
      return false;
    }
  }

  toggleModal(modalId, status) {
    if (status) {
      $('#' + modalId).modal({ show: true, backdrop: true });
    } else {
      $('#' + modalId).modal('hide');
    }
  }

  sendInvitation() {
    const loggedInPatient = localStorage.getItem('patient')
      ? JSON.parse(localStorage.getItem('patient'))['forename'] +
      ' ' +
      JSON.parse(localStorage.getItem('patient'))['surname']
      : '';
    const data = {};
    data['email_to'] = this.patientEmail.value;
    data['receiver_name'] = '';
    data['invited_by'] = loggedInPatient;
  }

  isOtpInserted(otp) {
    if (otp.value && otp.value.length && otp.value.length > 3) return false;
    else return true;
  }

  isSelected(id) {
    return this.selectedSubscriber?.id === id;
  }

  async unlinkSubscriber(user) {
    let message =
      '<p>You are about to remove your access to health data and profile information associated with the subscriber(s) listed below</p>';
    const providerInfo = `<p>${user.userProfile?.firstName} ${user.userProfile?.lastName}</p>`;
    message += `${providerInfo}<p>This change means that you will no longer have access to their special category data, particularly the data related to their health. Consequently, you will no longer be able to interpret test results, assess future test scheduling, or make decisions regarding any necessary treatment or intervention.</p>`;
    const result = await this.showWarningDialog(message);
    if (result.value) {
      const userId = user.id;
      if (this.myPatients) {
        this._apiService
          .removeSubscriberFromProvider(this.providerData.id, userId)
          .subscribe(async () => {
            await this.userService.fetchAuthorisedByUserData(this.userData.id);
          });
      } else {
        await this.userService.removeAuthorisedByUser(userId)
      }
    } else {
      return false;
    }
  }

  async showWarningDialog(message) {
    return Swal.fire({
      type: 'warning',
      html: message,
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonColor: '#dc3545',
      confirmButtonText: 'Remove Link',
    });
  }

  pageEvents(event: any) {
    this.limit = event.pageSize;
    if (event.pageIndex > this.pageNo) {
      this.pageNo = event.pageIndex;
    } else {
      this.pageNo = event.pageIndex;
    }
  }

  searchAndPaginateAndSort(array, pageNo, limitPerPage, currentSort, searchTerm) {
    let filteredArray = searchTerm ? array.filter(item => {
      const fullName = `${(item?.userProfile?.firstName || '').toLowerCase()} ${(item?.userProfile?.lastName || '').toLowerCase()}`;
      const lowerSearchTerm = searchTerm.toLowerCase();
      return (
        (item?.userProfile?.patientId.toLowerCase().includes(lowerSearchTerm)) ||
        (fullName.includes(lowerSearchTerm))
      );
    }) : array;

    filteredArray = this.selectedGenders && this.selectedGenders.length ? filteredArray.filter(item => {
      return (this.selectedGenders.includes(item.userProfile.gender))
    }) : filteredArray;

    filteredArray = this.selectedAgeGroups && this.selectedAgeGroups.length ? filteredArray.filter(item => {
      const age = this.calculateAge(new Date(item?.userProfile?.dateOfBirth));
      return this.isAgeInRange(age, this.selectedAgeGroups)
    }) : filteredArray;

    return this.paginateAndSort(filteredArray, pageNo, limitPerPage, currentSort);
  }

  isAgeInRange(age, ranges) {
    for (let i = 0; i < ranges.length; i++) {
      const range = ranges[i].split('-').map(Number);
      if (age >= range[0] && age < range[1]) {
        return true;
      }
    }
    return false;
  }

  calculateAge(birthDate) {
    const ageDate = new Date(Date.now() - birthDate.getTime());
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  }

  paginateAndSort(array, pageNo, limitPerPage, currentSort) {
    const { sortBy, sortOrder } = currentSort;

    array.sort((a, b) => {
      const valueA = this.getValue(a, sortBy);
      const valueB = this.getValue(b, sortBy);
      let comparison = 0;
      if (valueA === null || valueB === null) {
        comparison = valueA === null ? -1 : 1;
      } else if (sortBy == 'resultSet.observationDate' || sortBy == 'userProfile.dateOfBirth') {
        const dateA = new Date(valueA);
        const dateB = new Date(valueB);
        comparison = dateA.getTime() - dateB.getTime();
      } else if (typeof valueA === 'string' && typeof valueB === 'string') {
        comparison = valueA.localeCompare(valueB);
      } else {
        comparison = valueA > valueB ? 1 : valueA < valueB ? -1 : 0;
      }
      return sortOrder === 'desc' ? -comparison : comparison;
    });

    const startIndex = pageNo * limitPerPage;
    const endIndex = startIndex + limitPerPage;

    return array.slice(startIndex, endIndex);
  }

  getValue(obj, path) {
    const keys = path.split('.');
    let value = obj;
    for (const key of keys) {
      if (value && value.hasOwnProperty(key)) {
        value = value[key];
      } else {
        return null;
      }
    }
    return value;
  }

  sortData(event: any) {
    let sortKey;

    switch (event.active) {
      case 'firstName':
        sortKey = 'userProfile.firstName';
        break;
      case 'dateOfBirth':
        sortKey = 'userProfile.dateOfBirth';
        break;
      case 'latestTestDate':
        sortKey = 'resultSet.observationDate';
        break;
      case 'immunescore':
        sortKey = 'resultSet.nonAdaptiveScore';
        break;
      default:
        break;
    }

    if (sortKey) {
      this.currentSort = {
        sortBy: sortKey,
        sortOrder: event.direction,
      };
    }

    this.updateData();
  }

  getPageNumbers(): number[] {
    const totalPages = this.getTotalPages();
    return Array(totalPages)
      .fill(0)
      .map((_, i) => i);
  }

  getTotalPages(): number {
    return Math.ceil(this.totalRecords / this.limit);
  }

  goToNextPage() {
    this.pageNo++;
    this.updateData();
  }

  goToPreviousPage() {
    this.pageNo--;
    this.updateData();
  }

  goToPage(pageIndex: number) {
    this.pageNo = pageIndex;
    this.updateData();
  }

  isPageShow(pageNumber: number): boolean {
    const totalPages = this.getTotalPages();
    const pageNo = this.pageNo;

    if (pageNumber === pageNo
      || pageNumber === pageNo - 1
      || pageNumber === pageNo + 1
      || (pageNo === 0 && pageNumber === 2)
      || (pageNo === totalPages - 1 && pageNumber === pageNo - 2)) {
      return true;
    }

    return false;
  }

  updateData(): void {
    this.authorisedByUsers.data = this.searchAndPaginateAndSort(this.connectedAccounts, this.pageNo, this.limit, this.currentSort, this.searchText);
  }

  handleSearchChange() {
    this.updateData();
  }

  changeAgeGroupSelection() {
    this.pageNo = 0;
    this.updateData();
  }

  changeGenderSelection() {
    this.pageNo = 0;
    this.updateData()
  }

  toggleAllSelection(type) {
    this.pageNo = 0;

    if (type === 'ageGroup') {
      if (this.allSelectedForAgeGroup) {
        this.select.options.forEach((item: MatOption) => item.select());
      } else {
        this.select.options.forEach((item: MatOption) => item.deselect());
      }
    } else if (type == 'gender') {
      if (this.allSelectedForGender) {
        this.select2.options.forEach((item: MatOption) => item.select());
      } else {
        this.select2.options.forEach((item: MatOption) => item.deselect());
      }
    }
  }

  initializing() {

    this.router.events.subscribe(() => {
      this.setPageAction(this.router.url);
      this._testService.getTranslation('common.itemPerPage').then((res: string) => {
        this.customPaginatorIntl.itemsPerPageLabel = res;
      });

      if (this.myPatients) {
        this.isManager = this.userRole === 3001;
      }
    });

    this.subscription.add(this.userService.getUserDataObservable().subscribe(async (userData) => {
      this.userData = userData;

      const userId = userData.id

      if (!this.roleService.isProvider) {

        if (!(this.connectedAccounts.some((obj) => { return obj.id == userId; }))) {


          const resultSets: ResultSetResponse[] = await this.resultSetService.getResultSets({ userId })
          const scores: ScoreResults = await this.scoreService.getScores({ userId }).toPromise()

          resultSets.sort(function (a, b) {
            const dateA = new Date(a.observationDate).getTime();
            const dateB = new Date(b.observationDate).getTime();
            return dateA - dateB;
          });
          let latestResultsSet: Record<string, any>;
          if (resultSets.length) {
            latestResultsSet = resultSets[resultSets.length - 1];
            const latestScore = scores?.nonAdaptive?.find(score => score.resultSetId === latestResultsSet.id);
            latestResultsSet['nonAdaptiveScore'] = latestScore?.score || 0;
          }

          this.connectedAccounts.unshift({
            id: userData.id,
            userProfile: userData.userProfile,
            resultSet: latestResultsSet
          })
          this.totalRecords = this.connectedAccounts.length || 0;
          this.updateData();
        }
      }

    }));

    this.subscription.add(this.userService.getGenderOptions().subscribe((options) => {
      this.genders = options;
    }));

    this.subscription.add(this.userService.getAuthorisedUserDataObservable().subscribe((data) => {
      this.authorisedUsers.data = data;
    }));

    this.subscription.add(this.userService.getAuthorisedByUserDataObservable().subscribe(async (data) => {

      if (this.myAccounts && this.connectedAccounts.length > 1) {
        const foundIndex = this.connectedAccounts.findIndex(account => account.id === this.userData.id);

        if (foundIndex > -1) {
          const loginUser = { ...this.connectedAccounts[foundIndex] };
          this.connectedAccounts = [loginUser];
        }
      }

      data.forEach(element => {
        if (!this.connectedAccounts.some(obj => obj.id === element.id)) {
          this.connectedAccounts.push(element);
        }
      });

      this.totalRecords = this.connectedAccounts.length;
      this.updateData();
    }));

    this.subscription.add(this.providerService.getProviderDataObservable().subscribe((data) => {
      this.providerData = data;
    }));

    this.subscription.add(this.selectedSubscriberService.getUserDataObservable().subscribe((data) => {
      this.selectedSubscriber = data;
    }));
  }
}
