import { Component, OnInit, Input, OnChanges, Output, EventEmitter } from "@angular/core";
import { TestService } from "./../../../services/test.service";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { TranslateService } from "@ngx-translate/core";
import { ActivatedRoute } from "@angular/router";
declare const zingchart: any;

@Component({
  selector: "app-graph",
  templateUrl: "./graph.component.html",
  styleUrls: ["./graph.component.css"],
})
export class GraphComponent implements OnInit, OnChanges {
  @Input() pageName: any;
  @Input() graphData: any;
  @Output() clickedTest: EventEmitter<any> = new EventEmitter();
  userRole = JSON.parse(localStorage.getItem("roleId"));
  isClinician = false;
  isManager = false;
  isPatient = false;
  analytes = [];
  adaptiveAnalytes = [];
  adaptiveData: any;
  selectedAdaptiveAnalyte: any;
  selectedAnalytes = [];
  deselectAllLineChart: Boolean = false;
  selectAllLineChart: Boolean = true;
  dataForRerenderdChart: any;
  cellColors = [
    "#FF65A1",
    "#43B5AC",
    "#FFBE65",
    "#FF77AE",
    "#56C0B8",
    "#FFC777",
    "#FF81B5",
    "#60C6BE",
    "#FFCD81",
    "#FF8CBD",
    "#6BCCC6",
    "#FFD38C",
    "#FF94c3",
    "#73D1CB",
    "#FFD794",
    "#FFA4CC",
    "#88D8D3",
    "#FFDDA4",
    "#FFB4D5",
    "#9DDFDB",
    "#FFE3B4",
    "#FFCAE1",
    "#B9E8E5",
    "#FFEBCA",
    "#FFDFED",
    "#D5F1EF",
    "#FFF3DF",
  ];

  markers = [
    "square",
    "circle",
    "diamond",
    "triangle",
    "star5",
    "star6",
    "star7",
    "star8",
    "rpoly5",
    "gear5",
    "gear6",
    "gear7",
    "gear8",
  ];

  originalRes: any;
  dataForLineChart = [];
  @Input() allRanges: any;
  @Input() isDashboard: boolean;
  rangeData = [];
  adaptiveScores: any;
  isCovsAnalyte: boolean = false;
  isDefaultAnalyte: boolean = false;

  constructor(
    private testService: TestService,
    private ngxService: NgxUiLoaderService,
    private translate: TranslateService,
    private router: ActivatedRoute,
  ) {
    this.isPatient = this.userRole === 1001 ? true : false;
    this.isClinician = this.userRole === 2001 ? true : false;
    this.isManager = this.userRole === 3001 ? true : false;

    this.router.queryParams.subscribe((params) => {
      if (params.dashboard && params.dashboard === 'COVID') {
        this.isCovsAnalyte = true;
        this.isDefaultAnalyte = false;
      } else if (params.dashboard && params.dashboard === "Haematology") {
        this.isCovsAnalyte = false;
        this.isDefaultAnalyte = true;
      } else {
        this.isCovsAnalyte = true;
        this.isDefaultAnalyte = true;
      }
    });

  }

  ngOnInit() { }

  async ngOnChanges() {
    this.getAdeptiveScoreData()
      .then(async (res) => {
        this.getData();
        this.randerAdeptiveChart(this.selectedAdaptiveAnalyte);
      })
      .catch((e) => {
        console.error(e);
      });
  }

  getAdeptiveScoreData() {
    return new Promise((resolve, reject) => {

      this.testService.getAdaptiveData().subscribe(
        (res: any) => {
          this.adaptiveScores = JSON.parse(JSON.stringify(res)).SCORE;
          console.log("this.adaptiveScores", this.adaptiveScores);
          // await delete res.SCORE;
          delete res.SCORE;
          console.log("D: res ::", res);
          this.adaptiveData = JSON.parse(JSON.stringify(res));
          console.log(
            "D: Check this.adaptiveData :: ",
            Object.keys(this.adaptiveData).sort()
          );
          this.adaptiveAnalytes = Object.keys(this.adaptiveData).sort();
          console.log("this.adaptiveAnalytes ::", this.adaptiveAnalytes);
          this.selectedAdaptiveAnalyte = this.adaptiveAnalytes[0];
          this.ngxService.stop();
          resolve(true);
        },
        (err) => {
          console.log(err);
          this.ngxService.stop();
          reject(err);
        }
      );
    });
  }

  // async getData() {
  getData() {
    console.log("this.allRanges", this.allRanges);
    this.rangeData = [];
    if (this.allRanges && this.allRanges["reference_range"]) {
      Object.entries(this.allRanges["reference_range"]).forEach(([key, value]) => {
        if (typeof value === 'string') {
          const min = Number(value.split("-")[0].trim());
          const max = Number(value.split("-")[1].trim());
          const unit = this.allRanges["reference_unit"][key];
          this.rangeData.push({ label: `${key}: ${value}`, analytes: key, min, max, unit });
        }
      });
    } else {
      Object.entries(this.allRanges).forEach(([key, value]) => {
        if (typeof value === 'string') {
          const min = Number(value.split("-")[0]);
          const max = Number(value.split("-")[1].split(" ")[0]);
          const unit = value.split(" ")[1];
          this.rangeData.push({ label: `${key}: ${value}`, analytes: key, min, max, unit });
        }
      });
    }

    if (this.graphData.tests.length > 0) {
      this.analytes = JSON.parse(
        JSON.stringify(this.graphData.analytes)
      ).sort();
      this.selectedAnalytes = [];
      const analytesLength = this.analytes.length;
      for (let i = 0; i < analytesLength; i++) {
        let markerIndex = 0;
        let colorIndex = 0;
        if (i <= this.markers.length - 1) markerIndex = i;
        else markerIndex = i % this.markers.length;

        if (i <= this.cellColors.length - 1) colorIndex = i;
        else colorIndex = i % this.cellColors.length;

        this.selectedAnalytes.push({
          name: this.analytes[i],
          selectd: true,
          marker: this.markers[markerIndex],
          color: this.cellColors[colorIndex],
        });

      }



      // console.log(" this.selectedAnalytes ", this.selectedAnalytes)
      console.groupEnd();

      // this.originalRes = await JSON.parse(JSON.stringify(this.graphData.tests));
      this.originalRes = JSON.parse(JSON.stringify(this.graphData.tests));
      console.log("oia :: this.originalRes ::", this.originalRes);
      const labels = [];
      const dataset: any = {};
      const c3Data: any = [];
      this.originalRes.forEach(test => {
        labels.push(test.date);
      });

      this.analytes.forEach(cell => {
        dataset[cell] = [];
        this.originalRes.forEach(test => {
          dataset[cell].push(Number(test[cell]));
        });
      });
      this.dataForRerenderdChart = { dates: labels, cellTimeSeries: dataset };
      this.renderChart(this.dataForRerenderdChart);
    } else {
      const dataset: any = {};
      this.analytes.forEach(cell => {
        dataset[cell] = [];
      });
      this.originalRes = [];
      this.dataForRerenderdChart = { dates: [], cellTimeSeries: dataset };
      this.renderChart(this.dataForRerenderdChart);
    }

  }

  getRollingAverage(arrayData) {
    const rollingAverageData = [];
    // tslint:disable-next-line: prefer-for-of
    // console.log(arrayData)
    const arrayDataLength = arrayData && arrayData.length ? arrayData.length : 0;
    for (let i = 0; i < arrayDataLength; i++) {
      if (i === 0) {
        rollingAverageData.push(arrayData[i]);
      } else if (i === 1) {
        rollingAverageData.push(
          Number(((arrayData[i - 1] + arrayData[i]) / 2).toFixed(2))
        );
      } else {
        rollingAverageData.push(
          Number(
            ((arrayData[i - 2] + arrayData[i - 1] + arrayData[i]) / 3).toFixed(
              2
            )
          )
        );
      }
    }
    return this.isClinician ? arrayData : rollingAverageData;
  }

  // async renderChart(resForOldChart) {
  async renderChart(resForOldChart) {
    console.warn("resForOldChart ::", resForOldChart);

    this.dataForLineChart = [];
    await Promise.all(this.analytes.map(async (cell, index) => {
      const data = {};
      data[cell] = await this.getRollingAverage(resForOldChart.cellTimeSeries[cell]);
      this.dataForLineChart.push(data);
    }));

    resForOldChart.cellTimeSeries = this.dataForLineChart;
    window["customFncs"] = {};
    let chartConfig, immuneChartConfig;

    Promise.all([
      this.testService.getTranslation('reports.units'),
      this.testService.getTranslation('reports.score'),
      this.testService.getTranslation('reports.adaptiveScore')
    ]).then((res: any[]) => {
      chartConfig = {
        type: "line",
        // legend: {
        //   align: 'center',
        //   toggleAction: 'remove',
        //   layout: 'x10',
        // },
        plot: {
          tooltip: {
            text: this.isClinician
              ? `<p>%kl</p><p>%t: %v</p>`
              : `<p>%kl</p><p>%t (Avg): %v</p><p>%t (Actual): %data-actual</p>`,
            "html-mode": true,
            zIndex: 100,
          },
        },
        shapes: [

        ],
        scaleX: {
          autoFit: true,
          transform: {
            type: "date",
            all: "%dd %M %Y &nbsp;&nbsp;&nbsp;&nbsp;<br> %h:%i %A",
          },
          zooming: true,
        },
        scaleY: {
          label: {
            text: res[0],
          },
        },
        preview: {
          adjustLayout: true,
          live: true,
        },
        series: this.getZingChartData(resForOldChart),
      };


      immuneChartConfig = {
        type: "line",
        legend: {
          align: "center",
          toggleAction: "remove",
          // layout: 'x10',
        },
        plot: {
          tooltip: {
            text: `<p>%kl</p><p>%t: %v</p>`,
            "html-mode": true,
            zIndex: 100,
          },
        },
        scaleX: {
          // item: {
          //   'font-angle': -45
          // },
          transform: {
            type: "date",
            all: "%dd %M %Y &nbsp;&nbsp;&nbsp;&nbsp;<br> %h:%i %A",
          },
          zooming: true,
        },
        scaleY: {
          label: {
            text: res[0],
          },
        },
        preview: {
          adjustLayout: true,
          live: true,
        },
        series: this.getImmuneScoreData(resForOldChart, res[1], res[2]),
      };

      zingchart.TOUCHZOOM = "pinch";
      zingchart.ASYNC = true;
      console.log("chartConfig :: ", chartConfig);

      zingchart.render({
        id: "lineChart",
        data: chartConfig,
        height: 800,
        width: "100%",
        output: "canvas",
      });

      zingchart.render({
        id: "immuneScoreChart",
        data: immuneChartConfig,
        height: 400,
        width: "100%",
      });
      zingchart.shape_click = (p) => {
        if (p.shapeid === "deselect_all") {
          Promise.all(this.analytes).then((values) => {
            console.log("values", values);
            console.log(" p.id ", p.id);
            zingchart.exec(p.id, "hideplot", {
              plotindex: this.analytes.indexOf(values),
            });
          });
        }
      };
      zingchart.node_click = (e) => {
        console.log(e);
        if (e.id === "lineChart") {
          console.log(e["data-healthId"]);
          this.clickedTest.emit({ health_id: e["data-healthId"] });
        }
      };
      // this.randerAdeptiveChart(this.selectedAdaptiveAnalyte);
      window["customFncs"].formatTooltip1 = (p) => {
        const dataset = zingchart.exec("lineChart", "getdata");
        const series = dataset.graphset[p.graphindex].series;
        console.log("p", p);
        console.log("series", series);
      };
    });
  }

  getRanges() {
    this.testService.getAllReferenceRanges().subscribe(
      async (res) => {
        this.allRanges = JSON.parse(JSON.stringify(res));
        this.rangeData = [];
        await Promise.all(Object.entries(this.allRanges).map(async ([key, value]) => {
          if (typeof value === 'string') {
            const min = Number(value.split("-")[0]);
            const max = Number(value.split("-")[1].split(" ")[0]);
            const unit = value.split(" ")[1];
            this.rangeData.push({ label: `${key}: ${value}`, range: value, analytes: key, min, max, unit });
          }
        }));
      },
      (err) => {
        console.log(err);
      }
    );
  }


  getImmuneScoreData(resForOldChart, label1, label2) {
    const data = [];

    data.push({
      text: label1,
      values: this.getImmuneScoreSeriesData(this.originalRes),
      lineColor: "#0b5093",
      lineWidth: "1px",
      marker: {
        backgroundColor: "#0b5093",
        borderColor: "#0b5093",
        shadow: false,
        size: "3px",
      },
      scales: "scale-x,scale-y",
    });

    data.push({
      text: label2,
      values: this.getAdaptiveScoreSeriesData(),
      lineColor: "#4b998e",
      lineWidth: "1px",
      marker: {
        backgroundColor: "#4b998e",
        borderColor: "#4b998e",
        shadow: false,
        size: "3px",
      },
      scales: "scale-x,scale-y",
    });

    console.log(data);
    return data;
  }

  // async getImmuneScoreSeriesData(originalRes) {
  getImmuneScoreSeriesData(originalRes) {
    const data = [];
    originalRes.forEach((val, i) => {
      if (val.health_id && !isNaN(val.immunescore)) {
        data.push([
          Math.round(new Date(val.date.replace(" ", "T")).getTime()),
          Number(val.immunescore.toFixed(2)),
        ]);
      }
    });
    return data;
  }

  getAdaptiveScoreSeriesData() {
    const data = [];
    this.adaptiveScores.DATE.forEach((val, i) => {
      data.push([
        Math.round(new Date(val.replace(" ", "T")).getTime()),
        this.adaptiveScores.SCORE[i],
      ]);
    });
    return data;
  }

  // async getZingChartData(resForOldChart) {
  getZingChartData(resForOldChart) {
    console.warn("resForOldChart ::", resForOldChart);

    const data = [];
    Object.entries(resForOldChart.cellTimeSeries).forEach(([seriesKey, series]) => {
      let selectedAnalytesIndex = this.selectedAnalytes.findIndex(element => element.name == seriesKey);

      data.push({
        text: seriesKey,
        values: this.getSeriesData(series[seriesKey], resForOldChart.dates),
        lineColor: this.selectedAnalytes[selectedAnalytesIndex]["color"],
        marker: {
          backgroundColor: this.selectedAnalytes[selectedAnalytesIndex]["color"],
          type: this.selectedAnalytes[selectedAnalytesIndex]["marker"],
          size: 5,
        },
        legendMarker: {
          size: 5,
          type: this.selectedAnalytes[selectedAnalytesIndex]["marker"],
        },
        "data-healthId": this.getSeriesHealthId(series[seriesKey], resForOldChart.dates),
        "data-actual": this.getSeriesActualValue(series[seriesKey], seriesKey),
        scales: "scale-x,scale-y",
      });
    });

    console.log(" check data ", JSON.stringify(data, null, ' '));
    return data;
  }

  getSeriesData(series, dates) {
    const data = [];
    series.forEach((val, i) => {
      if (val && val !== null && val !== -1) {
        data.push([
          Math.round(new Date(dates[i].replace(" ", "T")).getTime()),
          val !== -1 ? Number(val) : null,
        ]);
      }
    });
    return data;
  }

  getSeriesHealthId(series, dates) {
    const data = [];
    series.forEach((val, i) => {
      if (val !== null && !isNaN(val)) {
        data.push(this.originalRes[i].health_id);
      }
    });
    return data;
  }

  getSeriesActualValue(series, dates) {
    const data = [];
    series.forEach((val, i) => {
      if (val !== null && !isNaN(val)) {
        data.push(this.originalRes[i][dates]);
      }
    });
    return data;
  }

  randerAdeptiveChart(analyte) {
    console.group("D: randerAdeptiveChart :: start");
    console.log("analyte ::", analyte);
    analyte = typeof analyte == "string" ? analyte : analyte["value"];
    console.log("this.rangeData ::", this.rangeData);
    console.log("this.adaptiveData ::", this.adaptiveData);
    let index = this.rangeData.findIndex(p => p.analytes === analyte);
    let allMax = new Array(this.adaptiveData[analyte].HEALTH_ID.length).fill(
      this.rangeData[index].max
    );
    let allMin = new Array(this.adaptiveData[analyte].HEALTH_ID.length).fill(
      this.rangeData[index].min
    );
    let dates = this.adaptiveData[analyte].DATE;
    let max = [];
    let tmax = [];
    let tmin = [];
    let tmean = [];
    let tavg = [];
    let min = [];
    let markerRange = [];
    let padding = (allMax[0] - allMin[0]) * 0.2;
    const value = JSON.parse(JSON.stringify(this.adaptiveData[analyte].VALUE))

    for (let n = 0; n < dates.length; n++) {
      let time = Math.round(new Date(dates[n].replace(" ", "T")).getTime());
      max.push([time, Number(allMax[n])]);
      tmax.push([time, Number(this.adaptiveData[analyte].adaptiveMax[n])]);
      tmean.push([time, Number(this.adaptiveData[analyte].MEAN[n])]);
      tavg.push([time, Number(this.adaptiveData[analyte].VALUE[n])]);
      tmin.push([time, Number(this.adaptiveData[analyte].adaptiveMin[n])]);
      min.push([time, Number(allMin[n])]);
      markerRange.push([
        time,
        [
          this.adaptiveData[analyte].adaptiveMin[n],
          this.adaptiveData[analyte].adaptiveMax[n],
        ],
      ]);
    }
    let chartConfig;
    Promise.all([
      this.testService.getTranslation('reports.maximumValue'),
      this.testService.getTranslation('reports.adaptiveMaxValue'),
      this.testService.getTranslation("reports.mean"),
      this.testService.getTranslation("reports.adaptiveMinValue"),
      this.testService.getTranslation("reports.minimumValue"),
      this.testService.getTranslation("reports.units"),
      this.testService.getTranslation("reports.actualValue"),
      this.testService.getTranslation("reports.range"),
    ]).then((res: any[]) => {
      const rangeIndex = this.rangeData.findIndex(p => p.analytes === analyte);
      const unit = rangeIndex !== -1 ? this.rangeData[rangeIndex].unit : '';

      chartConfig = {
        type: "mixed",
        plot: {
          tooltip: {
            text: `<p>%kl</p><p>${res[0]}: %data-max</p><p>${res[1]}: %data-tmax</p><p>${res[2]}: %data-mean</p><p>%t: %v</p><p>${res[3]}: %data-tmin</p><p>${res[4]}: %data-min</p>`,
            "html-mode": true,
            zIndex: 100,
          },
        },
        scaleX: {
          transform: {
            type: "date",
            all: "%dd %M %Y &nbsp;&nbsp;&nbsp;&nbsp;<br> %h:%i %A",
          },
          zooming: true,
        },
        scaleY: {
          label: {
            text: `${res[5]} ${unit}`,
            color: "#2D2D2D",
            fontWeight: "none",
          },
          guide: {
            visible: false,
          },
          minValue: allMin[0] - padding,
          maxValue: allMax[0] + padding,
        },
        preview: {
          adjustLayout: true,
          live: true,
        },
        legend: {
          align: "center",
          layout: "x5",
          padding: 20,
          paddingRight: 30,
        },
        plotarea: {
          margin: "55px",
        },
        series: [
          {
            type: "line",
            values: min,
            text: res[4],
            lineColor: "#599bdd",
            lineWidth: "5px",
            lineStyle: "dashed",
            tooltip: {
              visible: false,
            },
            marker: {
              backgroundColor: "white",
              borderColor: "#599bdd",
              shadow: false,
              visible: false,
              size: "0px",
            },
            shadow: false,
          },
          {
            text: res[3],
            type: "line",
            values: tmin,
            lineColor: "#afafaf",
            lineWidth: "3px",
            tooltip: {
              visible: false,
            },
            marker: {
              backgroundColor: "white",
              borderColor: "#afafaf",
              shadow: false,
              visible: false,
              size: "0",
            },
            shadow: false,
          },
          {
            text: res[2],
            type: "line",
            values: tmean,
            lineColor: "#989898",
            lineWidth: "1px",
            lineStyle: "dashed",
            tooltip: {
              visible: false,
            },
            marker: {
              backgroundColor: "white",
              borderColor: "#989898",
              shadow: false,
              visible: false,
              size: "0",
            },
            shadow: false,
          },
          {
            text: res[6],
            type: "line",
            values: tavg,
            lineColor: "#0b5094",
            lineWidth: "0px",
            marker: {
              backgroundColor: "#0b5094",
              borderColor: "#0b5094",
              shadow: false,
              size: "3px",
            },
            shadow: false,
            "data-max": allMax,
            "data-min": allMin,
            "data-tmax": this.adaptiveData[analyte].adaptiveMax,
            "data-tmin": this.adaptiveData[analyte].adaptiveMin,
            "data-mean": this.adaptiveData[analyte].MEAN,
          },
          {
            text: res[1],
            type: "line",
            values: tmax,
            lineColor: "#717171",
            lineWidth: "3px",
            tooltip: {
              visible: false,
            },
            marker: {
              backgroundColor: "white",
              borderColor: "#717171",
              shadow: false,
              visible: false,
              size: "0",
            },
            shadow: false,
          },
          {
            type: "line",
            text: res[0],
            values: max,
            lineColor: "#4578ac",
            lineWidth: "5px",
            lineStyle: "dashed",
            tooltip: {
              visible: false,
            },
            marker: {
              backgroundColor: "white",
              borderColor: "#4578ac",
              shadow: false,
              visible: false,
              size: "0",
            },
            shadow: false,
          },
          {
            type: "range",
            text: res[7],
            values: markerRange,
            tooltip: {
              visible: false,
            },
            legend: {
              visible: false,
            },
            backgroundColor: "#bbbaba",
            alphaArea: 0.5,
            guideLabel: {
              visible: false,
            },
            lineWidth: "0px",
            lineColor: "#7bcac4",
            marker: {
              visible: false,
            },
          },
        ],
      };
      zingchart.render({
        id: "myChart",
        data: chartConfig,
        height: "100%",
        width: "100%",
      });
    });
  }

  selectAnalyte(i) {
    this.ngxService.start();
    this.deselectAllLineChart = false;
    this.selectAllLineChart = false;
    let data = {};
    this.selectedAnalytes[i].selectd = this.selectedAnalytes[i].selectd ? false : true;
    console.log(" this.selectedAnalytes[i].selectd ", this.selectedAnalytes[i].selectd);
    let count = 0;
    // this.deselectAllLineChart = false
    // this.selectAllLineChart = false
    this.selectedAnalytes.forEach((element) => {
      if (element.selectd) {
        count++;
        data[element.name] = this.dataForRerenderdChart["cellTimeSeries"][this.selectedAnalytes.indexOf(element)][element.name];
      }
    });
    if (this.selectedAnalytes.length == count) {
      // All Selected
      this.deselectAllLineChart = false;
      this.selectAllLineChart = true;
      document.getElementById('deselectAll').style.pointerEvents = 'auto';
      document.getElementById('selectAll').style.pointerEvents = 'none';
    } else if (count) {
      this.deselectAllLineChart = false;
      this.selectAllLineChart = false;
      // Some of are selected
      //prevent clicking on disable : start
      document.getElementById('deselectAll').style.pointerEvents = 'auto';
      document.getElementById('selectAll').style.pointerEvents = 'auto';
      //prevent clicking on disable: end
    } else {
      this.deselectAllLineChart = true;
      this.selectAllLineChart = false;
      // No analyts are selected
      //prevent clicking on disable: start
      document.getElementById('deselectAll').style.pointerEvents = 'none';
      document.getElementById('selectAll').style.pointerEvents = 'auto';
      //prevent clicking on disable: end
    }

    //  call the render functions after select and unselect

    console.log(" ******* Data ******* ", data);
    this.renderChart({
      dates: this.dataForRerenderdChart["dates"],
      cellTimeSeries: JSON.parse(JSON.stringify(data)),
    });
    // renderChart
    console.groupEnd();
    this.ngxService.stop();
  }

  deselectAll(status) {
    this.ngxService.start();
    if (status) {
      this.deselectAllLineChart = true;
      this.selectAllLineChart = false;
      this.selectedAnalytes.forEach((element) => {
        element.selectd = false;
      });

      this.renderChart({
        dates: this.dataForRerenderdChart["dates"],
        cellTimeSeries: {},
      });
      //prevent clicking on disable:start
      document.getElementById('deselectAll').style.pointerEvents = 'none';
      document.getElementById('selectAll').style.pointerEvents = 'auto';
      //prevent clicking on disable:end

    } else {
      let data = {};
      this.selectAllLineChart = true;
      this.deselectAllLineChart = false;
      this.selectedAnalytes.forEach((element) => {
        element.selectd = true;
        data[element.name] =
          this.dataForRerenderdChart["cellTimeSeries"][
          this.selectedAnalytes.indexOf(element)
          ][element.name];
      });

      this.renderChart({
        dates: this.dataForRerenderdChart["dates"],
        cellTimeSeries: JSON.parse(JSON.stringify(data)),
      });
      //prevent clicking on disable: start
      document.getElementById('selectAll').style.pointerEvents = 'none';
      document.getElementById('deselectAll').style.pointerEvents = 'auto';
      //prevent clicking on disable: end


    }
    this.ngxService.stop();
  }
}
