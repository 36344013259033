import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TrendIndicatorService {

  constructor() { }

  getTrendImages(colorBand: string, direction: string) {
    const image = new Image();
    const iconTemplate = (icon: string) =>
      `<mat-icon matListIcon><i class='material-icons' style='color:red'>${icon}</i></mat-icon>`;

    const paths = {
      green: {
        UP: '../../assets/dashboard/green/top/2x.png',
        DOWN: '../../assets/dashboard/green/down/2x.png',
      },
      yellow: {
        UP: '../../assets/dashboard/yellow/top/2x.png',
        DOWN: '../../assets/dashboard/yellow/down/2x.png',
      },
      red: {
        UP: '../../assets/dashboard/pink/top/2x.png',
        DOWN: '../../assets/dashboard/pink/down/2x.png',
      },
    };

    const icons = {
      STABLE: 'keyboard_arrow_up',
      BACK: 'keyboard_arrow_left',
      NEXT: 'keyboard_arrow_right',
    };

    if (paths[colorBand] && paths[colorBand][direction]) {
      image.src = paths[colorBand][direction];
      return image;
    } else if (icons[direction]) {
      return iconTemplate(icons[direction]);
    }
  }


  getTextForTooltip(previous_score: number, colorBand: string, direction: string): string {
    const referenceRange = {
      g: "inside of the population reference range",
      y: "inside of the population reference range",
      p: "outside of the population reference range",
    };

    const referenceText = referenceRange[colorBand];
    if (!referenceText) {
      return '';
    }

    if (previous_score == null) {
      return `Current result is ${referenceText}`;
    }

    const directions = {
      UP: `higher than the previous result of ${previous_score.toFixed(2)}`,
      DOWN: `lower than the previous result of ${previous_score.toFixed(2)}`,
    };

    const directionText = directions[direction];
    if (directionText) {
      return `Current result is ${referenceText} and is ${directionText}`;
    }

    return `Current result is ${referenceText}`;
  }
}
