import { Component, Input, OnInit } from '@angular/core';

import { ResultSetResponse } from '../../../services/resultSet/types';
import { ResultSetService } from 'src/app/services/resultSet/resultSet.service';
import { ScoreResults } from 'src/app/services/score/types';

interface IAdaptiveScore {
  DATE: string[];
  SCORES: number[];
}

interface AnalyteData {
  adaptiveMax: number[];
  adaptiveMin: number[];
  DATE: string[];
  HEALTH_ID: number[];
  mean: number[];
  SD: number[];
  value: number[];
  min: number[];
  max: number[];
}

interface AdaptiveData {
  [analyteKey: string]: AnalyteData;
}

interface IChartData {
  chartId: string;
  adaptiveScores: IAdaptiveScore;
  adaptiveAnalytes: string[];
  adaptiveData: AdaptiveData;
  rangeData: Record<string, string>; // e.g. { ALB: "35.0-50.0 g/L" }
  fullNames: Record<string, string>;
  analyteSequence: string[];
}

@Component({
  selector: 'app-score-trends',
  templateUrl: './score-trends.component.html',
  styleUrls: ['./score-trends.component.css'],
})
export class ScoreTrendsComponent implements OnInit {
  @Input() resultSets: ResultSetResponse[] = [];
  @Input() scores: ScoreResults;
  @Input() isLoading: boolean;

  chartData: IChartData;
  adaptiveAnalytes = [];
  rangeData;
  adaptiveData = {};
  constructor(private readonly resultSetService: ResultSetService) { }

  ngOnInit(): void {

    this.adaptiveAnalytes = this.resultSetService.getAnalyteKeysFromSets(
      this.resultSets
    );

    this.rangeData = this.resultSetService.transformAnalyteRangesForReport(
      this.resultSets
    );

    let scores = JSON.parse(JSON.stringify(this.scores['adaptive'])) || [];

    scores.sort((a, b) => {
      const dateA = new Date(a.date).getTime();
      const dateB = new Date(b.date).getTime();

      if (isNaN(dateA) || isNaN(dateB)) {
        return 0;
      }

      return dateA - dateB;
    });

    scores.forEach((score) => {
      const rangeLocal = this.rangeData.flatMap(arr => arr).filter(obj => obj.id === score.resultSetId);

      const range = rangeLocal.find(obj => obj.analytes === score.analyteKey);

      if (this.adaptiveAnalytes.includes(score.analyteKey) && range != undefined) {

        this.adaptiveData[`${score.analyteKey}`];

        if (!this.adaptiveData[`${score.analyteKey}`]) {
          this.adaptiveData[`${score.analyteKey}`] = {
            adaptiveMax: [],
            adaptiveMin: [],
            DATE: [],
            HEALTH_ID: [],
            mean: [],
            value: [],
            min: [],
            max: [],
          };
        }

        this.adaptiveData[`${score.analyteKey}`]['adaptiveMax'].push(
          score.personalRangeMax
        );
        this.adaptiveData[`${score.analyteKey}`]['adaptiveMin'].push(
          score.personalRangeMin
        );
        this.adaptiveData[`${score.analyteKey}`]['mean'].push(
          score.personalRangeMean
        );

        this.adaptiveData[`${score.analyteKey}`]['min'].push(
          range.min
        );

        this.adaptiveData[`${score.analyteKey}`]['max'].push(
          range.max
        );

        this.adaptiveData[`${score.analyteKey}`]['DATE'].push(score.date);
        this.adaptiveData[`${score.analyteKey}`]['value'].push(score.value);
        this.adaptiveData[`${score.analyteKey}`]['HEALTH_ID'].push(
          score.resultSetId
        );
      }

    });
    this.setChartData();
  }

  setChartData() {
    this.chartData = {
      chartId: 'mixedDataChart',
      adaptiveScores: {
        DATE: this.resultSets.map((x) => x.observationDate),
        SCORES: this.resultSets.map((x) => x.adaptiveScores[0]?.score),
      },
      adaptiveAnalytes: this.adaptiveAnalytes,
      adaptiveData: this.adaptiveData,
      rangeData: this.rangeData,
      analyteSequence: this.adaptiveAnalytes,
      fullNames: {
        ALB: 'Albumin',
        ALP: 'Alkaline Phosphatase',
        ALT: 'Alanine Transferase',
        BASO: 'Basophils',
        BILI: 'Bilirubin',
        CA: 'Calcium',
        CREA: 'CREATININE',
        EOS: 'Eosinophils',
        GGT: 'Gamma GT',
        GLOB: 'Globulin',
        HCT: 'Haematocrit',
        HGB: 'Haemoglobin',
        K: 'Potassium',
        LYMPH: 'Lymphocytes',
        MCH: 'Mean Cell Haemoglobin',
        MCHC: 'Mean Cell Haemoglobin Concentration',
        MCV: 'Mean Cell Volume',
        MONO: 'Monocytes',
        NA: 'Sodium',
        NEUT: 'Neutrophils',
        PHOS: 'Phosphate',
        PLT: 'Platelet',
        PROT: 'Total Protein',
        RBC: 'Red Blood Cell',
        UREA: 'UREA',
        VIDC: 'Vitamin D (25 OH)',
        WBC: 'White Blood Cell',
      },
    };
  }
}
