<div class="d-flex justify-content-between px-4 mb-3 d-md-none d-lg-none">
  <div *ngFor="let option of reportOptions" (click)="performAction(option.action)" class="mobile-menu-button">
    <mat-icon style="color: #00978d">{{ option.icon }}</mat-icon>
  </div>
</div>

<div class="form-row dashboard-section-1" *ngIf="selectedResultSet">
  <div *ngIf="showScoreDetails" class="col-md-3 col-lg-2 col-sm-4 col-12 p-0">
    <app-score-details [selectedResultSet]="selectedResultSet" [previousResultSet]="previousResultSet"
      [isLoading]="isLoading || isLoadingTests"></app-score-details>
  </div>

  <app-analytes-graph class="p-0" [ngClass]="
      showScoreDetails ? 'col-md-9 col-lg-10 col-sm-8' : 'col-md-12 col-sm-12'
    " [selectedResultSet]="selectedResultSet" [selectedTestType]="selectedTestType"
    [previousResultSet]="previousResultSet" [testTypes]="testTypes" [isLoading]="isLoading || isLoadingTests"
    [isExpanded]="showScoreDetails" (onExpand)="toggleScoreDetails($event)"
    (onTestTypeSelect)="onTestTypeSelect($event)"></app-analytes-graph>
</div>

<app-historic-test-results class="mt-3" [resultSets]="resultSets" [isLoading]="isLoading || isLoadingTests"
  [selectedResultSet]="selectedResultSet" (onResultSetSelect)="onResultSetSelect($event)"></app-historic-test-results>