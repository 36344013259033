import { DatePipe } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { config } from '../../../config';
import * as moment from 'moment/moment';

@Component({
  selector: 'app-individual',
  templateUrl: './individual.component.html',
  styleUrls: ['./individual.component.css'],
})
export class IndividualComponent implements OnInit, OnChanges {
  @Input() inputData: any;
  @Input() tableData: any;
  @Output() getDateChanged: EventEmitter<any> = new EventEmitter();
  @Output() openTagModal: EventEmitter<any> = new EventEmitter();

  config = config;
  dates: any = [];
  uniqueDates: any = [];
  allRanges: any;
  testData: any = [];
  dateSelected: any;
  showRange = true;
  scoreType: string;
  rangeData: any;
  analytes: any = [];

  constructor(
    private changeRef: ChangeDetectorRef,
    private dateFilter: DatePipe
  ) {
  }

  ngOnInit() { }

  ngOnChanges(): void {

    this.dates = this.inputData?.inputDates || [];
    this.uniqueDates = this.inputData?.uniqueDates || [];
    this.rangeData = this.inputData?.newRangeData || [];
    this.analytes = this.inputData?.analytes || [];
    this.testData = this.tableData?.testData ? [...this.tableData.testData] : [];

    this.dateSelected = this.testData.length && this.testData[0].date ? this.dateFilter.transform(this.testData[0].date, config.regionConfig.dateFormatMid) : '';
    this.allRanges = this.tableData?.rangeData && this.tableData?.rangeData.length && this.tableData?.rangeData[0] ? this.tableData?.rangeData[0] : {};

    this.changeRef.detectChanges();
  }

  dateChanged(event) {
    this.getDateChanged.next(event);
  }

  getTimeForIndividualTest(time) {
    return moment.utc(time).format('hh:mm A');
  }

  getCount(recordIndex, cellIndex) {
    const count = this.testData[recordIndex]?.counts[cellIndex];

    if (!count || typeof count === 'string' || ['NaN', 'null', '-1.0', '-1'].includes(count)) {
      return '--';
    }

    return +parseFloat(count).toFixed(3);
  }

  handleAddTag(record) {
    this.openTagModal.next(record);
  }

  getCellBgColor(recordIndex: number, cellIndex: number, cell: string): boolean {
    const referenceRange = this.allRanges?.reference_range[cell];

    if (!referenceRange) {
      return false;
    }

    const [min, max] = referenceRange.split('-').map(Number);
    const count = this.testData[recordIndex].counts[cellIndex];

    if (!this.isValidCount(count)) {
      return false;
    }

    const numericCount = Number(count);
    return numericCount > max || numericCount < min;
  }

  private isValidCount(count: any): boolean {
    return count !== '-1' && count !== '-1.0' && count !== 'NAN' && count !== 'null';
  }
}
